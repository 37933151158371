import React, { useEffect } from "react";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useSaveDepartmentsMutation } from "../../services/settings.service";
import {ClipLoader} from "react-spinners";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import alertify from "alertifyjs";
import { Select } from "antd";


const FormSchema = Yup.object().shape({
  name: Yup.string()
      .min(2, "Minimum 3 letters")
      .required("Please enter a name"),
});

const DepartmentForm = ({item, departments}) => {

  const [saveDepartment, {isLoading, data, error}] = useSaveDepartmentsMutation();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FormSchema),
  });

  useEffect(() => {
    if (data && data.success) {
      alertify.success(data?.message);
      document.getElementById('close-modal').click()
    }

    if (error) {
      alertify.error(error?.message);
    }
  }, [data, error]);

  useEffect(() => {
    if (item) {
      setValue('name', item.name);
      setValue('parent_department_id', item.parent_department_id)
      setValue('id', item.id);
    }
  }, [item]);

  const save = payload => {
    saveDepartment(payload);
  }

  return (
    <>
      {/* Add/Edit Leavetype Modal */}
      <div id="branch_form" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{item ? 'Edit' : 'Add New'} Department</h5>
              <button
                type="button"
                id="close-modal"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(save)}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Name <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <input
                        className={`form-control ${
                          errors?.name ? "error-input" : ""
                        }`}
                        type="text"
                        value={value}
                        onChange={onChange}
                        autoComplete="false"
                      />
                    )}
                  />
                  <span className="text-danger">
                    {errors?.name?.message}
                  </span>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Parent Department
                  </label>
                  <Controller
                    name="code"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        name="parent_department_id"
                        placeholder="Select..."
                        size="large"
                        value={value}
                        options={
                            (departments &&
                                departments.map((department) => ({
                                    label: department.name,
                                    value: department.id,
                                }))) ||
                            []
                        }
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                  >
                    {!isLoading ? 'Save' : <ClipLoader color="#ffffff" />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Add/Edit Branch Modal */}
    </>
  );
};

export default DepartmentForm;
