import React from "react";
import ReactCountryFlag from "react-country-flag";

const FlagIcons = () => {
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            {/* Chart */}
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Flag Icons</div>
                </div>
                <div className="card-body">
                  <div className="icons-items">
                    <ul className="icons-list">
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="US"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ad"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ae"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="af"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ag"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ai"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="al"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="am"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ao"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ar"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="as"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="at"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="au"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="aw"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ax"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="az"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ba"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="bb"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="bd"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="be"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="bf"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="bg"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="bh"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="bi"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="bj"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="bl"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="bm"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="bn"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="bo"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="bq"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="br"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="bs"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="bt"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="bv"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="bw"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="by"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="bz"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ca"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="cc"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="cd"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="cf"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="cg"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ch"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ci"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ck"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="cl"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="cm"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="cn"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="co"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="cr"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="cu"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="cv"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="cw"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="cx"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="cy"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="cz"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="de"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="dj"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="dk"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="dm"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="do"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="dz"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ec"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ee"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="eg"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="er"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="es"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="et"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="fi"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="fj"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="fk"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="fm"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="fo"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="fr"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ga"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="gb"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="gd"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ge"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="gf"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="gg"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="gh"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="gi"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="gl"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="gm"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="gn"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="gp"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="gq"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="gr"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="gs"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="gt"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="gu"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="gw"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="gy"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="hk"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="hn"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="hr"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ht"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="hu"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="id"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ie"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="il"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="im"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="in"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="io"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="iq"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ir"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="is"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="it"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="je"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="jm"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="jo"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="jp"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ke"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="kg"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="kh"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ki"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="km"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="kn"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="kp"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="kr"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="kw"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ky"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="kz"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="la"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="lb"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="lc"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="li"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="lk"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="lr"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ls"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="lt"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="lu"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="lv"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ly"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ma"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="mc"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="md"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="me"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="mg"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="mh"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="mk"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ml"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="mm"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="mn"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="mo"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="mp"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="mq"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="mr"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ms"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="mt"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="mu"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="mv"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="mw"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="mx"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="my"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="mz"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="na"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="nc"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ne"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="nf"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ng"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ni"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="nl"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="no"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="np"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="nr"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="nu"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="nz"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="om"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="pa"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="pe"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="pf"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="pg"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ph"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="pk"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="pl"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="pm"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="pn"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="pr"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ps"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="pt"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="pw"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="py"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="qa"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="re"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ro"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="rs"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ru"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="rw"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="sa"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="sb"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="sc"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="sd"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="se"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="sg"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="sh"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="si"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="sk"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="sl"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="sm"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="sn"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="so"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="sr"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ss"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="st"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="sv"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="sx"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="sy"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="sz"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="tc"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="td"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="tf"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="tg"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="th"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="tj"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="tk"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="tl"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="tm"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="tn"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="to"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="tr"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="tt"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="tv"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="tw"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="tz"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ua"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ug"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="um"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="us"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="uy"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="uz"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="va"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="vc"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ve"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="vg"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="vi"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="vn"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="vu"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="wf"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ws"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="ye"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="yt"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="za"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="zm"
                        />
                      </li>
                      <li>
                        <ReactCountryFlag
                          style={{ fontSize: "1.5em" }}
                          svg
                          countryCode="zw"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* /Chart */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlagIcons;
