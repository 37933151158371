import Select from 'react-select'
import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { profileuploadimg } from "../../Routes/ImagePath"
import { customStyles, options } from "../../utils"
import { useForm, Controller } from "react-hook-form";
import {ClipLoader} from "react-spinners";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import alertify from "alertifyjs";
import { EmployeeSchema } from '../../utils/validationSchema'
import DatePicker from "react-datepicker";
import { useSaveEmployeesMutation, useUpdateEmployeeMutation } from '../../services/employee.service'


export const PersonalInfoForm = ({empId, profile, branches, departments, designations, onNext, user}) => {
    const [saveInfo, {isLoading, data, error}] = useSaveEmployeesMutation();
    const [updateInfo, {isLoading: isUpdating, data: updateData, error: updateError}] = useUpdateEmployeeMutation();

    const {
        control,
        reset,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(EmployeeSchema),
    });

    useEffect(() => {
        if (data && data.success) {
            onNext(data?.data.id);
        }

        if (updateData && updateData?.success) {
            onNext(updateData?.data);
        }

        if ((data && !data.success) || (updateData && !updateData.success)) {
            alertify.error(data?.message | updateData?.message);
        }
    
        if (error || updateError) {
            alertify.error(error?.message | updateError?.message);
        }
    }, [data, updateData, error, updateError]);

    const save = payload => {
        if (empId) {
            updateInfo({data: payload, id: empId});
        } else {
            saveInfo(payload);
        }
    }
    
    useEffect(() => {
        if (profile) {
            const form = {...profile};
            form.designation_id =  profile?.designation?.id;
            form.department_id =  profile?.department?.id;
            form.branch_id =  profile?.branch?.id;

            reset(form);
        }
    }, [profile]);
      
    return (
            <form onSubmit={handleSubmit(save)}>
                <div className="form-upload-profile">
                    <h6 className="">
                        Profile Image <span> *</span>
                    </h6>
                    <div className="profile-pic-upload">
                        <div className="profile-pic">
                            <span>
                                <img
                                    src={profileuploadimg}
                                    alt="Img"
                                />
                            </span>
                        </div>
                        <div className="employee-field">
                            <div className="mb-0">
                                <div className="image-upload mb-0">
                                    <input type="file" />
                                    <div className="image-uploads">
                                        <h4>Upload</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="img-reset-btn">
                                <Link to="#">Reset</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact-input-set">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    First Name <span className="text-danger"> *</span>
                                </label>
                                <Controller
                                    name="first_name"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.first_name ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        value={value}
                                        onChange={onChange}
                                        autoComplete="false"
                                    />
                                    )}
                                />
                                <span className="text-danger">
                                    {errors?.first_name?.message}
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Last Name <span className="text-danger"> *</span>
                                </label>
                                <Controller
                                    name="last_name"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.last_name ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        value={value}
                                        onChange={onChange}
                                        autoComplete="false"
                                    />
                                    )}
                                />
                                <span className="text-danger">
                                    {errors?.last_name?.message}
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Job Title <span className="text-danger"> *</span>
                                </label>
                                <Controller
                                    name="designation_id"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <Select
                                            className="select"
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={designations?.find(c => c.value === value)}
                                            optionFilterProp="children"
                                            onChange={({value}) => onChange(value)}
                                            options={designations || []}
                                        />
                                    )}
                                />
                                <span className="text-danger">
                                    {errors?.designation_id?.message}
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Address <span className="text-danger">*</span>
                                </label>
                                <Controller
                                    name="address"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                    <input
                                        className={`form-control ${
                                        errors?.address ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        value={value}
                                        onChange={onChange}
                                        autoComplete="false"
                                    />
                                    )}
                                />
                                <span className="text-danger">
                                    {errors?.address?.message}
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Place of Birth 
                                </label>
                                <Controller
                                    name="place_of_birth"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <input
                                            className={`form-control`}
                                            type="text"
                                            value={value}
                                            onChange={onChange}
                                            autoComplete="false"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Phone Number 1<span className="text-danger"> *</span>
                                </label>
                                <Controller
                                    name="phone_number"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <input
                                            className={`form-control ${
                                            errors?.phone_number ? "is-invalid" : ""
                                            }`}
                                            type="text"
                                            value={value}
                                            onChange={onChange}
                                            autoComplete="false"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Phone Number 2
                                </label>
                                <Controller
                                    name="phone_number_2"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <input
                                            className={`form-control`}
                                            type="text"
                                            value={value}
                                            onChange={onChange}
                                            autoComplete="false"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="input-block mb-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <label className="col-form-label">
                                        Email <span className="text-danger"> *</span>
                                    </label>
                                </div>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <input
                                            className={`form-control ${
                                            errors?.email ? "is-invalid" : ""
                                            }`}
                                            type="email"
                                            value={value}
                                            onChange={onChange}
                                            autoComplete="false"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Gender <span className="text-danger"> *</span>
                                </label>
                                <Controller
                                    name="gender"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <Select
                                            className="select"
                                            placeholder="Select"
                                            styles={customStyles}
                                            optionFilterProp="children"
                                            value={options.genders.find(c => c.value === value)}
                                            onChange={({value}) => onChange(value)}
                                            options={options.genders}
                                        />
                                    )}
                                />
                                <span className="text-danger">
                                    {errors?.gender?.message}
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Marital Status <span className="text-danger"> *</span>
                                </label>
                                <Controller
                                    name="marital_status"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <Select
                                            className="select"
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={options.maritalStatus.find(c => c.value === value)}
                                            onChange={({value}) => onChange(value)}
                                            optionFilterProp="children"
                                            options={options.maritalStatus}
                                        />
                                    )}
                                />
                                <span className="text-danger">
                                    {errors?.marital_status?.message}
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="input-block mb-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <label className="col-form-label">
                                        Date of Birth <span className="text-danger"> *</span>
                                    </label>
                                </div>
                                <Controller
                                    name="date_of_birth"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <div className="cal-icon">
                                            <DatePicker
                                                selected={value || null}
                                                onChange={onChange}
                                                className="form-control floating datetimepicker"
                                                type="date"
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                    )}
                                />
                                <span className="text-danger">
                                    {errors?.date_of_birth?.message}
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="input-block mb-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <label className="col-form-label">
                                        Nationality
                                    </label>
                                </div>
                                <Controller
                                    name="nationality"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <input
                                            className={`form-control`}
                                            type="text"
                                            value={value}
                                            onChange={onChange}
                                            autoComplete="false"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="input-block mb-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <label className="col-form-label">
                                        State of Origin
                                    </label>
                                </div>
                                <Controller
                                    name="state_of_origin"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <input
                                            className={`form-control`}
                                            type="text"
                                            value={value}
                                            onChange={onChange}
                                            autoComplete="false"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="input-block mb-3">
                                <div className="d-flex justify-content-between align-items-ce   nter">
                                    <label className="col-form-label">
                                        Religion
                                    </label>
                                </div>
                                <Controller
                                    name="religion"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <input
                                            className={`form-control`}
                                            type="text"
                                            value={value}
                                            onChange={onChange}
                                            autoComplete="false"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Branch <span className="text-danger"> *</span>
                                </label>
                                <Controller
                                    name="branch_id"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <Select
                                            isDisabled={
                                                !['HR Admin', 'Admin', 'HR Assistant'].includes(
                                                user?.role?.name
                                                )
                                            }
                                            className="select"
                                            placeholder="Select"
                                            value={branches?.find(c => c.value === value)}
                                            onChange={({value}) => onChange(value)}
                                            styles={customStyles}
                                            optionFilterProp="children"
                                            options={branches || []}
                                        />
                                    )}
                                />
                                <span className="text-danger">
                                    {errors?.branch_id?.message}
                                </span>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Department <span className="text-danger"> *</span>
                                </label>
                                <Controller
                                    name="department_id"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <Select
                                            className="select"
                                            placeholder="Select"
                                            value={departments?.find(c => c.value === value)}
                                            onChange={({value}) => onChange(value)}
                                            styles={customStyles}
                                            optionFilterProp="children"
                                            options={departments || []}
                                        />
                                    )}
                                />
                                <span className="text-danger">
                                    {errors?.department_id?.message}
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Date Joined 
                                </label>
                                <Controller
                                    name="join_date"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <div className="cal-icon">
                                            <DatePicker
                                                selected={value || null}
                                                onChange={onChange}
                                                className="form-control floating datetimepicker"
                                                type="date"
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        
                        <div className="col-lg-12 d-flex justify-content-between form-wizard-button">
                            <button
                                className="button btn-lights reset-btn"
                                type="reset"
                                onClick={reset}
                            >
                                Reset
                            </button>
                            <div className='text-end'>
                                
                                <button
                                    className="btn btn-primary wizard-next-btn"
                                    type="submit" 
                                >
                                    {(!isLoading || isUpdating) ? 'Save & Next' : <ClipLoader color='#ffffff' />}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
    )
}