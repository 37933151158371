import React, { useState } from "react";
import SearchBox from "../../../../components/SearchBox";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { useGetTerminationTypesQuery } from "../../../../services/settings.service";
import { toggleDelete } from "../../../../redux/slices/ui.slice";
import { TERMINATION_TYPES } from "../../../../services/ENDPOINTS";
import { useDispatch } from "react-redux";
import TerminationTypeForm from "../../../../components/modelpopup/TerminationTypeForm";

const TerminationType = () => {
  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();
  const {data, isLoading, error} = useGetTerminationTypesQuery();

  const doDelete = (e, item) => {
    // e.currentTarget.closest('tr').remove()
    dispatch(
      toggleDelete({
        open: true,
        title: 'Termination Type',
        url: `${TERMINATION_TYPES}/${item.id}`,
        ele: e.currentTarget,
        parent: 'tr'
      })
    );
  }

 
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text, r, i) => (
        <div className="ant-table-row ant-table-row-level-0 ">
          <span>{i + 1}</span>
        </div>
      ),
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => (
        <div className="ant-table-row ant-table-row-level-0 ">
          <span>{text}</span>
        </div>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      title: "Status",
      sorter: true,
      render: () => (
        <div className="ant-table-row ant-table-row-level-0 ">
          <Link
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            to="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="far fa-dot-circle text-success" /> Active
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link to="#" className="dropdown-item">
              <i className="far fa-dot-circle text-success" /> Active
            </Link>
            <Link to="#" className="dropdown-item">
              <i className="far fa-dot-circle text-danger" /> Inactive
            </Link>
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      sorter: true,
      className: "text-end",
      render: (t, r) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#termination_type_form"
              onClick={() => setSelected(r)}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={(e) => doDelete(e, r)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Manage Termination Types"
            title="Dashboard"
            subtitle="Termination Types"
            modal="#termination_type_form"
            name="Add Termination Type"
          />
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  columns={columns}
                  dataSource={data?.data}
                  loading={isLoading}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        <TerminationTypeForm type={selected}  />
      </div>
    </>
  );
};

export default TerminationType;
