import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {Select} from "antd";
import { useLazySearchEmployeesQuery, useSaveLoansMutation } from "../../services/employee.service";
import dayjs from "dayjs";
import { useForm, Controller } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import alertify from "alertifyjs";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { LoanSchema } from "../../utils/validationSchema";
import { useSelector } from "react-redux";
import { options } from "../../utils";

const {Option } = Select;

const LoanFormModal = ({item}) => {
  const {user} = useSelector((state) => state.user);

  const branch_id = (!['HR Admin', 'Admin', 'HR Assistant'].includes(user?.role?.name)) ? user?.branch?.id : ''
  const [employees, setEmployees] = useState([]);

  const [searchEmployees, {data: searchData, error: searchError}] = useLazySearchEmployeesQuery();
  const [saveResignation, {data, isLoading, isSuccess, error}] = useSaveLoansMutation();


  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoanSchema),
  });

  useEffect(() => {
    if (searchData) {
      setEmployees(searchData?.data)
    }
  }, [searchData, searchError])

  useEffect(() => {
    if (isSuccess && data?.success){
      alertify.success(data?.message);
      document.getElementById('close-modal').click();
      setValue('employee_id', "");
      setValue('amount', "");
      setValue('tenure', "");
      setValue('monthly_repayment', "");
      setValue('reason', "");
      setValue('id', "");
    }

    if (data && !data.success)
      alertify.error(data.errors);

    if (error) 
      alertify.error(error?.message);

  }, [data, error, isSuccess]);


  useEffect(() => {
    if (item) {
      setEmployees([...employees, {
        id: item.employee.id,
        name: item.employee.first_name + ' ' + item.employee.last_name,
        designation: item.employee.designation,
        department: item.department
      }]);
      setValue('amount', item.amount)
      setValue('tenure', item.tenure)
      setValue('monthly_repayment', item.monthly_repayment)
      setValue('employee_id', item.employee.id);
      setValue('reason', item.reason);
      setValue('id', item.id);
    }
  }, [item]);

  const findEmployees = (value) => {
    if (value) {
      searchEmployees({q:value, branch_id});
    } else {
      setEmployees([]);
    }
  }

  const save = payload => {
    payload.start_date = dayjs(payload.start_date).format('YYYY-MM-DD');
    payload.end_date = dayjs(payload.end_date).format('YYYY-MM-DD');
    // console.log(payload)
    saveResignation(payload);
  }

  return (
    <div>
      <div id="loans_modal" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{item ? 'Edit Loan Request' : 'Loan Request Form'}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-modal"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(save)}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Select Employee <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="employee_id"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        showSearch
                        size="large"
                        value={value}
                        style={{width: '100%'}}
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        onSearch={findEmployees}
                        onChange={onChange}
                        notFoundContent={null}
                      >
                        {employees.map(d => <Option key={d.id} value={d.id}>{d.name}</Option>)}
                      </Select>
                    )}
                  />
                  <span className="text-danger">
                      {errors?.employee_id?.message}
                  </span>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Amount <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="amount"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <input
                        className={`form-control ${
                          errors?.amount ? "is-invalid" : ""
                        }`}
                        type="text"
                        value={value}
                        onChange={(value) => {
                          onChange(value);
                          const tenure = getValues('tenure');
                          const amount = value.target.value;
                          if (tenure) {
                            const monthlyRepayment = parseFloat(amount) / tenure;
                            setValue('monthly_repayment', monthlyRepayment.toFixed(2));
                          }
                        }}
                        autoComplete="false"
                      />
                    )}
                  />
                  <span className="text-danger">
                    {errors?.amount?.message}
                  </span>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Duration <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="tenure"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        showSearch
                        size="large"
                        value={value}
                        style={{width: '100%'}}
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        options={options.tenures}
                        onChange={(value) => {
                          onChange(value);
                          const amount = getValues('amount');
                          if (amount) {
                            const monthlyRepayment = parseFloat(amount) / value;
                            setValue('monthly_repayment', monthlyRepayment.toFixed(2));
                          }
                        }}
                        notFoundContent={null}
                      />
                      
                    )}
                  />
                  <span className="text-danger">
                      {errors?.tenure?.message}
                  </span>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Monthly Repayment
                  </label>
                  <Controller
                    name="monthly_repayment"
                    control={control}
                    render={({ field: { value } }) => (
                      <input
                        className="form-control"
                        type="text"
                        // defaultValue="Web Developer"
                        value={value}
                        readOnly
                      />
                    )}
                  />
                   
                </div>
                
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Reason <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="reason"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <textarea
                        className="form-control"
                        rows={4}
                        defaultValue={""}
                        onChange={onChange}
                      >{value}</textarea>
                    )}
                  />
                  <span className="text-danger">
                    {errors?.reason?.message}
                  </span>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Guarantors <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="guarantors"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        showSearch
                        mode="multiple"
                        size="large"
                        value={value}
                        style={{width: '100%'}}
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        onSearch={findEmployees}
                        onChange={onChange}
                        notFoundContent={null}
                      >
                        {employees.map(d => <Option key={d.id} value={d.id}>{d.name}</Option>)}
                      </Select>
                    )}
                  />
                  <span className="text-danger">
                      {errors?.employee_id?.message}
                  </span>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                  >
                    {!isLoading ? 'Save' : <ClipLoader color="#ffffff" />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    
    </div>
  );
};

export default LoanFormModal;
