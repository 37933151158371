import React from "react";
import { Link } from "react-router-dom";
import { ProjectDetails } from "./ProfileContent";
import dayjs from "dayjs";
import { Skeleton } from "antd";
import { EmergencyContacts } from "./Partials/EmergencyContacts";
import { FamilyInformation } from "./Partials/FamilyInformation";
import PersonalInfoModal from "../../../components/modelpopup/Profile/PersonalInfoModal";
import { EducationInformation } from "./Partials/EducationInformation";
import { WorkExperience } from "./Partials/WorkExperience";

const ProfileTab = ({details, user}) => {

  const familyInfoData = [
    {
      id: 1,
      name: "Leo",
      relationship: "Brother",
      dob: "Feb 16th, 2019",
      phone: "9876543210",
    },
  ];


  return (
    <>
      <div className="tab-content">
        <div
          id="emp_profile"
          className="pro-overview tab-pane fade show active"
        >
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Personal Informations{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#personal_info_modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  {!details ?
                    <Skeleton paragraph={{rows: 6}}/>
                    :
                    <ul className="personal-info">
                      <li>
                        <div className="title">Phone:</div>
                        <div className="text"><a href="">{details?.phone_number}</a></div>
                      </li>
                      {details?.user &&
                      <li>
                        <div className="title">Email:</div>
                        <div className="text"><a href="">{details?.user?.email}</a></div>
                      </li>}
                      <li>
                        <div className="title">Birthday:</div>
                        <div className="text">{dayjs(details?.date_of_birth).format('do MMMM')}</div>
                      </li>
                      <li>
                        <div className="title">Address:</div>
                        <div className="text">{details?.address}</div>
                      </li>
                      <li>
                        <div className="title">Gender:</div>
                        <div className="text">{details?.gender}</div>
                      </li>
                      <li>
                        <div className="title">Place of Birth.</div>
                        <div className="text">{details?.place_of_birth}</div>
                      </li>
                      <li>
                        <div className="title">State of Origin</div>
                        <div className="text">{details?.state_of_origin || "N/A"}</div>
                      </li>
                      <li>
                        <div className="title">Nationality</div>
                        <div className="text">{details?.nationality}</div>
                      </li>
                      <li>
                        <div className="title">Religion</div>
                        <div className="text">{details?.religion}</div>
                      </li>
                      <li>
                        <div className="title">Marital status</div>
                        <div className="text">{details?.marital_status}</div>
                      </li>
                    </ul>}
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <EmergencyContacts details={details} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 d-flex">
              <FamilyInformation details={details} />
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Place Of Worship{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#family_info_modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <div className="table-responsive">
                    <table className="table table-nowrap">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Relationship</th>
                          <th>Date of Birth</th>
                          <th>Phone</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {familyInfoData.map((item) => (
                          <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.relationship}</td>
                            <td>{item.dob}</td>
                            <td>{item.phone}</td>
                            <td className="text-end">
                              <div className="dropdown dropdown-action">
                                <Link
                                  aria-expanded="false"
                                  data-bs-toggle="dropdown"
                                  className="action-icon dropdown-toggle"
                                  to="#"
                                >
                                  <i className="material-icons">more_vert</i>
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link to="#" className="dropdown-item">
                                    <i className="fa fa-pencil m-r-5" /> Edit
                                  </Link>
                                  <Link to="#" className="dropdown-item">
                                    <i className="fa fa-trash m-r-5" /> Delete
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 d-flex">
              <EducationInformation details={details} />
            </div>

            <div className="col-md-6 d-flex">
              <WorkExperience details={details} />
            </div>
          </div>
        </div>
        <ProjectDetails />
        {/* Bank Statutory Tab */}

        {/* Bank Statutory Tab */}
        {/*  Bank Tab */}
      </div>
      {/* Model Popup*/}
      {/* <PersonalInformationModelPopup /> */}
      <PersonalInfoModal data={details} user={user} />
    </>
  );
};

export default ProfileTab;
