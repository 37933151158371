
import { EducationalInfoForm } from "../../Forms/EducationalForm";

const EducationModal = ({data, refresh}) => {

  return (
    <div
        id="education_info"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Next Of Kin Details</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">

              <EducationalInfoForm empId={data?.id} profile={data} onNext={refresh} isEditing={true} />
            </div>
          </div>
        </div>
      </div>
  )
}
export default EducationModal;
