import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Table } from "antd";
import { Avatar_02 } from "../../../Routes/ImagePath/index";
import TerminationModal from "../../../components/modelpopup/TerminationModal";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useLazyGetTerminationsQuery } from "../../../services/hr.service";
import dayjs from "dayjs";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import { TERMINATIONS } from "../../../services/ENDPOINTS";
import { useDispatch } from "react-redux";
import { FilterBox } from "../../../components/FilterBox";

const Termination = () => {
  const [selected, setSelected] = useState(null);
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({});
  const [results, setResult] = useState([]);
  const dispatch = useDispatch();

  const [getResults, {data, isFetching, error}] = useLazyGetTerminationsQuery();

  useEffect(() => {
    getResults({page: 1, search});
  }, []);

  useEffect(() => {
    if (data) {
      setResult(data?.data?.result || []);
      setPagination(data?.data.meta);
    }
  }, [data]);


  const columns = [
    {
      title: '',
      render: (text, record) =>
        // hasPermission('write', 'resignations', user?.role?.permissions) && (
          <div className="dropdown dropdown-action text-right">
            <a
              href="javascript:;"
              className="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="material-icons">more_vert</i>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => setSelected(record)}
                data-toggle="modal"
                data-target="#termination_modal"
              >
                <i className="fa fa-pencil m-r-5" /> Edit
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => doDelete(e, record)}
                
              >
                <i className="fa fa-trash m-r-5" /> Delete
              </a>
            </div>
          </div>
        // ),
    },
    // {
    //   title: "#",
    //   dataIndex: "id",
    //   sorter: (a, b) => a.id.length - b.id.length,
    //   render: (t, r, i) => i + 1
    // },
    {
      title: "Terminated Employee",
      dataIndex: "name",
      render: (text, record) => (
        <h2 className="table-avatar d-flex">
        <NavLink to={`/employee/${record.employee.id}`} className="avatar">
          <img
            alt=""
            src={
              record.employee.emp_code
                ? `http://129.18.184.239:8091/auth_files/photo/${record.employee.emp_code}.jpg`
                : Avatar_02
            }
          />
        </NavLink>
        <NavLink to={`/employee/${record.employee.id}`} className="profile-split d-flex flex-column">
          {`${record.employee.first_name} ${record.employee.last_name}`}
          <span>{record.employee.designation.name}</span>
        </NavLink>
      </h2>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a.department.length - b.department.length,
      render: (t, r) => r.employee.department.name
    },
    {
      title: "Termination Type",
      dataIndex: "terminationtype",
      render: (t, r) => r.termination_type.name
    },
    {
      title: "Termination Date",
      dataIndex: "termination_date",
      sorter: (a, b) => a.termination_date.length - b.termination_date.length,
      render: (text, record) => dayjs(text).format('DD MMM YYYY'),
    },

    {
      title: "Reason",
      dataIndex: "reason",
      sorter: (a, b) => a.reason.length - b.reason.length,
    },

    {
      title: "Notice Date",
      dataIndex: "notice_date",
      sorter: (a, b) => a.notice_date.length - b.notice_date.length,
      render: (text, record) => dayjs(text).format('DD MMM YYYY'),
    },
    {
      title: "Action",
      sorter: true,
      render: (t, r) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => setSelected(r)}
              data-bs-toggle="modal"
              data-bs-target="#termination_modal"
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={(e) => doDelete(e, r)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const doDelete = (e, item) => {
    // e.currentTarget.closest('tr').remove()
    dispatch(
      toggleDelete({
        open: true,
        title: 'Termination',
        url: `${TERMINATIONS}/${item.id}`,
        ele: e.currentTarget,
        parent: 'tr'
      })
    );
  }

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Manage Termination"
            title="Dashboard"
            subtitle="Termination"
            modal="#termination_modal"
            name="Add Termination"
          />
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <FilterBox getResults={(val) => {
                  setSearch(val);
                  getResults({page: 1, search: val});
                }} />
              
                <Table
                  className="table-striped"
                  columns={columns}
                  loading={isFetching}
                  dataSource={results}
                  rowKey={(record) => record.id}
                  pagination={{ 
                    defaultPageSize: 100, 
                    total: pagination?.total,
                    // showSizeChanger: true, 
                    // pageSizeOptions: ['10', '20', '30', '50', '100']
                    onChange: (page, pageSize) => {
                      getResults({page, search});
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      <TerminationModal item={selected} />
    </>
  );
};

export default Termination;
