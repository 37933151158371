import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Avatar_02,
} from "../../../Routes/ImagePath";
import EmployeeListFilter from "../../../components/EmployeeListFilter";
import { useGetEmployeesMutation } from "../../../services/employee.service";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import { EMPLOYEE } from "../../../services/ENDPOINTS";
import AddEmployee from "../../../components/modelpopup/Crm/AddEmployee";
import ExportLeads from "../../../components/modelpopup/Crm/ExportLeads";
import { useGetBranchesQuery, useGetDepartmentsQuery, useGetDesignationsQuery } from "../../../services/settings.service";
import { Table } from "antd";
import dayjs from "dayjs";
import { hasPermission } from "../../../utils";

const AllEmployee = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [displayType, setDisplayType] = useState('grid');
  const [selected, setSelected] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { data: branchData } = useGetBranchesQuery();
  const { data: deptData } = useGetDepartmentsQuery();
  const { data: designationData } = useGetDesignationsQuery();

  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 100,
    total: 0,
    lastPage: 0,
    nextPage: 0,
    prevPage: 0,
  });

  const [filterData, setFilter] = useState({
    employee_name: "",
    branch_id: !['HR Admin', 'Admin', 'HR Assistant'].includes(user?.role?.name)
      ? user?.branch?.id
      : '',
    department_id: "",
    designation_id: "",
  });

  const [getEmployees, {data, isFetching, isSuccess}] = useGetEmployeesMutation();

  useEffect(() => {
    getEmployees({
      filter: filterData, 
      page: pagination.page, 
      limit: pagination.perPage
    });
  }, [])

  useEffect(() => {
    if(data) {
      setEmployeeData(data?.data?.result || []);
      setPagination(data?.data.meta);
    }
  }, [data]);

  const doDelete = (e, id) => {
    // e.currentTarget.closest('tr').remove()
    dispatch(
      toggleDelete({
        open: true,
        title: 'Employee',
        url: `${EMPLOYEE}/${id}`,
        ele: e.currentTarget,
        parent: 'tr'
      })
    );
  }

  const resetPage = () => {
    setFilter({
      employee_name: "",
      branch_id: !['HR Admin', 'Admin', 'HR Assistant'].includes(user?.role?.name)
        ? user?.branch?.id
        : '',
      department_id: "",
      designation_id: "",
    });

    getEmployees({filter: {
      employee_id: "",
      branch_id: !['HR Admin', 'Admin', 'HR Assistant'].includes(user?.role?.name)
        ? user?.branch?.id
        : '',
      department_id: "",
      designation_id: "",
    }, page: 1, limit: 100})
  }
  
  const columns = [
    {
      title: 'EMP ID',
      dataIndex: 'emp_code',
      sorter: (a, b) => a.emp_code.length - b.emp_code.length,
    },
    {
      title: 'Name',
      dataIndex: 'first_name',
      render: (text, record) => (
        <h2 className="table-avatar d-flex">
          <Link
            to={
              hasPermission('read', 'employees', user?.role?.permissions)
                ? `/profile/${record.id}`
                : ''
            }
            className="avatar"
          >
            <img
              alt=""
              src={
                record.emp_code
                  ? `http://129.18.184.239:8091/auth_files/photo/${record.emp_code}.jpg`
                  : Avatar_02
              }
            />
          </Link>
          <Link
            to={
              hasPermission('read', 'employees', user?.role?.permissions)
                ? `/profile/${record.id}`
                : ''
            }
            className="profile-split d-flex flex-column"
          >
            {record.first_name} {record.last_name}
            <span>
              {record.designation?.name} ({record.department.name})
            </span>
          </Link>
        </h2>
      ),
      sorter: (a, b) => a.first_name.length - b.first_name.length,
    },
    {
      title: 'Branch',
      dataIndex: 'branch',
      sorter: (a, b) => a.branch.length - b.branch.length,
      render: (text, record) => record.branch?.name,
    },
    {
      title: 'Mobile',
      dataIndex: 'phone_number',
      sorter: (a, b) => a.phone_number.length - b.phone_number.length,
    },

    {
      title: 'Join Date',
      dataIndex: 'join_date',
      sorter: (a, b) => a.join_date.length - b.join_date.length,
      render: (text, record) =>
        record.join_date
          ? dayjs(record.join_date).format('DD MMM YYYY')
          : text,
    },
    {
      title: 'Contract Status',
      dataIndex: 'contract_status',
      render: (text, record) => (
        <span
          className={`text-capitalize
            ${
              text !== 'active'
                ? 'badge bg-inverse-danger'
                : 'badge bg-inverse-success'
            }`}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      render: (text, record) =>
        record.created_at
          ? dayjs(record.created_at).format('DD MMM YYYY')
          : text,
    },
  ];

  if (
    hasPermission('write', 'employees', user?.role?.permissions) ||
    hasPermission('delete', 'employees', user?.role?.permissions)
  ) {
    columns.push({
      title: 'Action',
      render: (text, record) =>
        (hasPermission('write', 'employees', user?.role?.permissions) ||
          hasPermission('delete', 'employees', user?.role?.permissions)) && (
          <div className="dropdown dropdown-action text-right">
            <a
              href="javascript:;"
              className="action-icon dropdown-toggle"
              aria-expanded="false"
            >
              <i className="material-icons">more_vert</i>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <a
                className="dropdown-item"
                href="javascript:;"
                data-toggle="modal"
                data-target="#employee_modal"
                onClick={() => {
                  setSelected(record);
                }}
              >
                <i className="fa fa-pencil m-r-5" /> Edit
              </a>
              {!record.emp_code && record.contract_status === 'active' && (
                <a
                  className="dropdown-item"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#add_to_biotime"
                  onClick={() => {
                    setSelected(record);
                  }}
                >
                  <i className="la la-user-plus m-r-5" /> Add To BioTime
                </a>
              )}
              <a
                className="dropdown-item"
                href="javascript:;"
                onClick={() => {
                  setSelected(record);
                }}
              >
                <i className="la la-user-plus m-r-5" /> Update EMP ID
              </a>
              <a
                className="dropdown-item"
                href="javascript:;"
                onClick={(e) => doDelete(e, record.id)}
              >
                <i className="fa fa-trash-o m-r-5" /> Delete
              </a>
            </div>
          </div>
        ),
    });
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-md-4">
                <h3 className="page-title">Employees</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item">HR</li>
                  <li className="breadcrumb-item active">Employees</li>
                </ul>
              </div>
              <div className="col-md-8 float-end ms-auto">
                <div className="d-flex title-head">
                  <div className="view-icons">
                    <Link to="#" className="grid-view btn btn-link" onClick={resetPage}><i className="las la-redo-alt" /></Link>
                    <Link to="#" className={showFilter ? "list-view btn btn-link active-filter" : "list-view btn btn-link"} id="filter_search" onClick={() => setShowFilter(!showFilter)}><i className="las la-filter" /></Link>
                    <Link
                      to="#"
                      className={`grid-view btn btn-link ${displayType === 'grid' ? 'active' : ''} mx-2`}
                      onClick={() => setDisplayType('grid')}
                    >
                      <i className="fa fa-th" />
                    </Link>
                    <Link 
                      to="#" 
                      className={`list-view btn btn-link ${displayType === 'list' ? 'active' : ''}`} 
                      onClick={() => setDisplayType('list')}>
                      <i className="fa fa-bars" />
                    </Link>
                  </div>
                  <div className="form-sort">
                    <Link to="#" className="list-view btn btn-link" data-bs-toggle="modal" data-bs-target="#export"><i className="las la-file-export" />Export</Link>
                  </div>
                  <Link to="#" className="btn add-btn" data-bs-toggle="modal" data-bs-target="#employee_form"><i className="la la-plus-circle" /> Add Employee</Link>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {showFilter && <EmployeeListFilter 
            branches={branchData?.data}
            departments={deptData?.data}
            designations={designationData?.data}
            filter={filterData} 
            setFilter={(data) => setFilter(data)} 
            user={user} 
            submit={() => getEmployees({filter: filterData, page: 1, limit: 100})} 
          /> }
          <hr />

          <div className="row">
            {displayType === 'grid' ?
              employeeData.map((employee) => (
                <div
                  className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                  key={employee.id}
                >
                  <div className="profile-widget">
                    <div className="profile-img">
                      <Link to={`/profile/${employee.id}`} className="avatar">
                        <img src={
                            employee.emp_code
                              ? `http://129.18.184.239:8091/auth_files/photo/${employee.emp_code}.jpg`
                              : Avatar_02
                          } alt="" />
                      </Link>
                    </div>
                    <div className="dropdown profile-action">
                      <Link
                        to="#"
                        className="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </Link>
                      <div className="dropdown-menu dropdown-menu-right">
                      <a
                        className="dropdown-item"
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#employee_modal"
                        onClick={() => {
                          setSelected(employee);
                        }}
                      >
                        <i className="fa fa-pencil m-r-5" /> Edit
                      </a>
                      {!employee.emp_code && employee.contract_status === 'active' && (
                        <a
                          className="dropdown-item"
                          href="javascript:;"
                          data-toggle="modal"
                          data-target="#add_to_biotime"
                          onClick={() => {
                            setSelected(employee);
                          }}
                        >
                          <i className="la la-user-plus m-r-5" /> Add To BioTime
                        </a>
                      )}
                      <a
                        className="dropdown-item"
                        href="javascript:;"
                        onClick={() => {
                          setSelected(employee);
                        }}
                      >
                        <i className="la la-user-plus m-r-5" /> Update EMP ID
                      </a>
                      <a
                        className="dropdown-item"
                        href="javascript:;"
                        onClick={(e) => doDelete(e, employee.id)}
                      >
                        <i className="fa-regular fa-trash-can m-r-5" /> Delete
                      </a>
                      </div>
                    </div>
                    <h4 className="user-name m-t-10 mb-0 text-truncate no-wrap">
                      <Link to={`/profile/${employee.id}`}>{employee.first_name} {employee.last_name}</Link>
                    </h4>
                    <div className="small text-muted">{employee.designation.name} ({employee.department.name})</div>
                  </div>
                </div>
              ))
            :
              <div className="col-md-12">
                <div className="table-responsive">
                  <Table
                    className="table-striped"
                    columns={columns}
                    loading={isFetching}
                    dataSource={employeeData}
                    rowKey={(record) => record.id}
                    pagination={{ 
                      defaultPageSize: 100, 
                      total: pagination?.total,
                      onChange: (page, pageSize) => {
                        getEmployees({
                          filter: filterData, 
                          page,
                          limit: pagination.perPage
                        });
                      },
                    }}
                  />
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <AddEmployee  
        branches={branchData?.data?.map(branch => ({value: branch.id, label: branch.name}))} 
        designations={designationData?.data?.map(row => ({value:row.id, label: row.name}))} 
        departments={deptData?.data?.map(row => ({value: row.id, label: row.name}))}
      />
      <ExportLeads />
      
    </div>
  );
};

export default AllEmployee;
