import dayjs from "dayjs";

export const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#008a42" : "#fff",
        color: state.isFocused ? "#fff" : "#000",
        "&:hover": {
            backgroundColor: "#008a42",
        },
    }),
};

export const options = {
    genders : [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
    ],
    maritalStatus : [
        { value: 'Single', label: 'Single' },
        { value: 'Married', label: 'Married' },
        { value: 'Divorced', label: 'Divorced' },
    ],
    tenures: [
        {value: 1, label: '1 Month'},
        {value: 3, label: '3 Months'},
        {value: 6, label: '6 Months'},
        {value: 8, label: '8 Months'},
        {value: 10, label: '10 Months'},
        {value: 12, label: '12 Months'},
        {value: 18, label: '18 Months'},
        {value: 24, label: '24 Months'},
    ]
}

export const humanFileSize = (size) => {
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

export const modules = [
    { name: 'Employees', slug: 'employees' },
    { name: 'HR', slug: 'hr' },
    { name: 'Leaves', slug: 'leaves' },
    { name: 'Loans', slug: 'loans' },
    { name: 'Payroll', slug: 'payroll' },
    { name: 'Attendance', slug: 'attendance' },
    { name: 'Promotions', slug: 'promotions' },
    { name: 'Terminations', slug: 'terminations' },
    { name: 'Transfers', slug: 'transfers' },
    { name: 'Resignations', slug: 'resignations' },
    { name: 'Administration', slug: 'administration' }
];

export const dateFormats = [
    { value: 'DD.MM.YYYY', label: dayjs().format('DD.MM.YYYY') },
    { value: 'DD/MM/YYYY', label: dayjs().format('DD/MM/YYYY') },
    { value: 'DD-MM-YYYY', label: dayjs().format('DD-MM-YYYY') },
    { value: 'MM/DD/YYYY', label: dayjs().format('MM/DD/YYYY') },
    { value: 'YYYY/MM/DD', label: dayjs().format('YYYY/MM/DD') },
    { value: 'YYYY-MM-DD', label: dayjs().format('YYYY-MM-DD') },
    { value: 'MMM DD YYYY', label: dayjs().format('MMM DD YYYY') },
    { value: 'DD MMM YYYY', label: dayjs().format('DD MMM YYYY') },
];

export const hasModule = (module, permissions) => {
    if (permissions) {
        return permissions.some(item => item.module === module);
    }
    return false
}

export const hasPermission = (permission, moduleKey, permissions) => {
    if (permissions) {
        const module = permissions.find(item => item.module === moduleKey);
        if (module) {
            return module.permissions.includes(permission);
        } else {
            return false;
        }
    }
    return false;
}

export const getSetting = (settings, key) => {
    if (settings !== null) {
        const setting = settings.find(setting => setting.option === key);
        if (setting)
            return setting.value;
    }
    return null;
}

export const slugify = (text) => {
    if (!text)
        return false;

    return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
}

export const formatNumber = (number) => 
    !number
      ? 0
      : parseFloat(number).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });