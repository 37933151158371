import React from "react";
import {
  Activity,
  Airplay,
  AlertCircle,
  AlertOctagon,
  AlertTriangle,
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  Anchor,
  Aperture,
  ArrowDown,
  ArrowDownCircle,
  ArrowDownLeft,
  ArrowDownRight,
  ArrowLeft,
  ArrowLeftCircle,
  ArrowRight,
  ArrowRightCircle,
  ArrowUp,
  ArrowUpCircle,
  ArrowUpLeft,
  ArrowUpRight,
  AtSign,
  Award,
  BarChart,
  BarChart2,
  Battery,
  BatteryCharging,
  Bell,
  BellOff,
  Bluetooth,
  Bold,
  Book,
  BookOpen,
  Bookmark,
  Box,
  Briefcase,
  Calendar,
  Camera,
  CameraOff,
  Cast,
  Check,
  CheckCircle,
  CheckSquare,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronsDown,
  ChevronsLeft,
  ChevronsRight,
  ChevronsUp,
  Chrome,
  Circle,
  Clipboard,
  Clock,
  Cloud,
  CloudDrizzle,
  CloudLightning,
  CloudOff,
  CloudRain,
  CloudSnow,
  Code,
  Codepen,
  Command,
  Compass,
  Copy,
  CornerDownLeft,
  CornerDownRight,
  CornerLeftDown,
  CornerLeftUp,
  CornerRightDown,
  CornerRightUp,
  CornerUpLeft,
  CornerUpRight,
  CreditCard,
  Crop,
  Crosshair,
  Database,
  Delete,
  Disc,
  DollarSign,
  Download,
  DownloadCloud,
  Droplet,
  Edit,
  Edit2,
  Edit3,
  ExternalLink,
  Eye,
  EyeOff,
  Facebook,
  FastForward,
  Feather,
  File,
  FileMinus,
  FilePlus,
  FileText,
  Film,
  Filter,
  Flag,
  Folder,
  FolderMinus,
  FolderPlus,
  GitBranch,
  GitCommit,
  GitMerge,
  GitPullRequest,
  GitHub,
  Gitlab,
  Globe,
  Grid,
  HardDrive,
  Hash,
  Headphones,
  Heart,
  HelpCircle,
  Home,
  Image,
  Inbox,
  Info,
  Instagram,
  Italic,
  Layers,
  Layout,
  LifeBuoy,
  Link,
  Link2,
  List,
  Loader,
  Lock,
  LogIn,
  LogOut,
  Mail,
  Map,
  MapPin,
  Maximize,
  Maximize2,
  Menu,
  MessageCircle,
  MessageSquare,
  Mic,
  MicOff,
  Minimize,
  Minimize2,
  Minus,
  MinusCircle,
  MinusSquare,
  Monitor,
  Moon,
  MoreHorizontal,
  MoreVertical,
  Move,
  Music,
  Navigation,
  Navigation2,
  Octagon,
  Package,
  Paperclip,
  Pause,
  PauseCircle,
  Percent,
  Phone,
  PhoneCall,
  PhoneForwarded,
  PhoneIncoming,
  PhoneMissed,
  PhoneOff,
  PhoneOutgoing,
  PieChart,
  Play,
  PlayCircle,
  Plus,
  PlusCircle,
  PlusSquare,
  Pocket,
  Power,
  Printer,
  Radio,
  RefreshCcw,
  RefreshCw,
  Repeat,
  Rewind,
  RotateCcw,
  RotateCw,
  Rss,
  Save,
  Scissors,
  Search,
  Send,
  Server,
  Settings,
  Share,
  Share2,
  Shield,
  ShieldOff,
  ShoppingBag,
  ShoppingCart,
  Shuffle,
  Sidebar,
  SkipBack,
  SkipForward,
  Slack,
  Slash,
  Sliders,
  Smartphone,
  Speaker,
  Square,
  Star,
  StopCircle,
  Sun,
  Sunrise,
  Sunset,
  Tablet,
  Tag,
  Target,
  Terminal,
  Thermometer,
  ThumbsDown,
  ThumbsUp,
  ToggleLeft,
  ToggleRight,
  Trash,
  Trash2,
  TrendingDown,
  TrendingUp,
  Triangle,
  Truck,
  Tv,
  Twitter,
  Type,
  Umbrella,
  Underline,
  Unlock,
  Upload,
  UploadCloud,
  User,
  UserCheck,
  UserMinus,
  UserPlus,
  UserX,
  Users,
  Video,
  VideoOff,
  Voicemail,
  Volume,
  Volume1,
  Volume2,
  VolumeX,
  Watch,
  Wifi,
  WifiOff,
  Wind,
  X,
  XCircle,
  XSquare,
  Zap,
  ZapOff,
  ZoomIn,
  ZoomOut,
} from "react-feather";

const FeatherIcons = () => {
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="content-page-header">
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            {/* Chart */}
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Feather Icon</div>
                </div>
                <div className="card-body">
                  <div className="icons-items">
                    <ul className="icons-list">
                      <li>
                        <Activity
                          className="feather-activity"
                          data-bs-toggle="tooltip"
                          title="feather-activity"
                        />
                      </li>
                      <li>
                        <Airplay
                          className="feather-airplay"
                          data-bs-toggle="tooltip"
                          title="feather-airplay"
                        />
                      </li>
                      <li>
                        <AlertCircle
                          className="feather-alert-circle"
                          data-bs-toggle="tooltip"
                          title="feather-alert-circle"
                        />
                      </li>
                      <li>
                        <AlertOctagon
                          className="feather-alert-octagon"
                          data-bs-toggle="tooltip"
                          title="feather-alert-octagon"
                        />
                      </li>
                      <li>
                        <AlertTriangle
                          className="feather-alert-triangle"
                          data-bs-toggle="tooltip"
                          title="feather-alert-triangle"
                        />
                      </li>
                      <li>
                        <AlignCenter
                          className="feather-align-center"
                          data-bs-toggle="tooltip"
                          title="feather-align-center"
                        />
                      </li>
                      <li>
                        <AlignJustify
                          className="feather-align-justify"
                          data-bs-toggle="tooltip"
                          title="feather-align-justify"
                        />
                      </li>
                      <li>
                        <AlignLeft
                          className="feather-align-left"
                          data-bs-toggle="tooltip"
                          title="feather-align-left"
                        />
                      </li>
                      <li>
                        <AlignRight
                          className="feather-align-right"
                          data-bs-toggle="tooltip"
                          title="feather-align-right"
                        />
                      </li>
                      <li>
                        <Anchor
                          className="feather-anchor"
                          data-bs-toggle="tooltip"
                          title="feather-anchor"
                        />
                      </li>
                      <li>
                        <Aperture
                          className="feather-aperture"
                          data-bs-toggle="tooltip"
                          title="feather-aperture"
                        />
                      </li>
                      <li>
                        <ArrowDown
                          className="feather-arrow-down"
                          data-bs-toggle="tooltip"
                          title="feather-arrow-down"
                        />
                      </li>
                      <li>
                        <ArrowDownCircle
                          className="feather-arrow-down-circle"
                          data-bs-toggle="tooltip"
                          title="feather-arrow-down-circle"
                        />
                      </li>
                      <li>
                        <ArrowDownLeft
                          className="feather-arrow-down-left"
                          data-bs-toggle="tooltip"
                          title="feather-arrow-down-left"
                        />
                      </li>
                      <li>
                        <ArrowDownRight
                          className="feather-arrow-down-right"
                          data-bs-toggle="tooltip"
                          title="feather-arrow-down-right"
                        />
                      </li>
                      <li>
                        <ArrowLeft
                          className="feather-arrow-left"
                          data-bs-toggle="tooltip"
                          title="feather-arrow-left"
                        />
                      </li>
                      <li>
                        <ArrowLeftCircle
                          className="feather-arrow-left-circle"
                          data-bs-toggle="tooltip"
                          title="feather-arrow-left-circle"
                        />
                      </li>
                      <li>
                        <ArrowRight
                          className="feather-arrow-right"
                          data-bs-toggle="tooltip"
                          title="feather-arrow-right"
                        />
                      </li>
                      <li>
                        <ArrowRightCircle
                          className="feather-arrow-right-circle"
                          data-bs-toggle="tooltip"
                          title="feather-arrow-right-circle"
                        />
                      </li>
                      <li>
                        <ArrowUp
                          className="feather-arrow-up"
                          data-bs-toggle="tooltip"
                          title="feather-arrow-up"
                        />
                      </li>
                      <li>
                        <ArrowUpCircle
                          className="feather-arrow-up-circle"
                          data-bs-toggle="tooltip"
                          title="feather-arrow-up-circle"
                        />
                      </li>
                      <li>
                        <ArrowUpLeft
                          className="feather-arrow-up-left"
                          data-bs-toggle="tooltip"
                          title="feather-arrow-up-left"
                        />
                      </li>
                      <li>
                        <ArrowUpRight
                          className="feather-arrow-up-right"
                          data-bs-toggle="tooltip"
                          title="feather-arrow-up-right"
                        />
                      </li>
                      <li>
                        <AtSign
                          className="feather-at-sign"
                          data-bs-toggle="tooltip"
                          title="feather-at-sign"
                        />
                      </li>
                      <li>
                        <Award
                          className="feather-award"
                          data-bs-toggle="tooltip"
                          title="feather-award"
                        />
                      </li>
                      <li>
                        <BarChart
                          className="feather-bar-chart"
                          data-bs-toggle="tooltip"
                          title="feather-bar-chart"
                        />
                      </li>
                      <li>
                        <BarChart2
                          className="feather-bar-chart-2"
                          data-bs-toggle="tooltip"
                          title="feather-bar-chart-2"
                        />
                      </li>
                      <li>
                        <Battery
                          className="feather-battery"
                          data-bs-toggle="tooltip"
                          title="feather-battery"
                        />
                      </li>
                      <li>
                        <BatteryCharging
                          className="feather-battery-charging"
                          data-bs-toggle="tooltip"
                          title="feather-battery-charging"
                        />
                      </li>
                      <li>
                        <Bell
                          className="feather-bell"
                          data-bs-toggle="tooltip"
                          title="feather-bell"
                        />
                      </li>
                      <li>
                        <BellOff
                          className="feather-bell-off"
                          data-bs-toggle="tooltip"
                          title="feather-bell-off"
                        />
                      </li>
                      <li>
                        <Bluetooth
                          className="feather-bluetooth"
                          data-bs-toggle="tooltip"
                          title="feather-bluetooth"
                        />
                      </li>
                      <li>
                        <Bold
                          className="feather-bold"
                          data-bs-toggle="tooltip"
                          title="feather-bold"
                        />
                      </li>
                      <li>
                        <Book
                          className="feather-book"
                          data-bs-toggle="tooltip"
                          title="feather-book"
                        />
                      </li>
                      <li>
                        <BookOpen
                          className="feather-book-open"
                          data-bs-toggle="tooltip"
                          title="feather-book-open"
                        />
                      </li>
                      <li>
                        <Bookmark
                          className="feather-bookmark"
                          data-bs-toggle="tooltip"
                          title="feather-bookmark"
                        />
                      </li>
                      <li>
                        <Box
                          className="feather-box"
                          data-bs-toggle="tooltip"
                          title="feather-box"
                        />
                      </li>
                      <li>
                        <Briefcase
                          className="feather-briefcase"
                          data-bs-toggle="tooltip"
                          title="feather-briefcase"
                        />
                      </li>
                      <li>
                        <Calendar
                          className="feather-calendar"
                          data-bs-toggle="tooltip"
                          title="feather-calendar"
                        />
                      </li>
                      <li>
                        <Camera
                          className="feather-camera"
                          data-bs-toggle="tooltip"
                          title="feather-camera"
                        />
                      </li>
                      <li>
                        <CameraOff
                          className="feather-camera-off"
                          data-bs-toggle="tooltip"
                          title="feather-camera-off"
                        />
                      </li>
                      <li>
                        <Cast
                          className="feather-cast"
                          data-bs-toggle="tooltip"
                          title="feather-cast"
                        />
                      </li>
                      <li>
                        <Check
                          className="feather-check"
                          data-bs-toggle="tooltip"
                          title="feather-check"
                        />
                      </li>
                      <li>
                        <CheckCircle
                          className="feather-check-circle"
                          data-bs-toggle="tooltip"
                          title="feather-check-circle"
                        />
                      </li>
                      <li>
                        <CheckSquare
                          className="feather-check-square"
                          data-bs-toggle="tooltip"
                          title="feather-check-square"
                        />
                      </li>
                      <li>
                        <ChevronDown
                          className="feather-chevron-down"
                          data-bs-toggle="tooltip"
                          title="feather-chevron-down"
                        />
                      </li>
                      <li>
                        <ChevronLeft
                          className="feather-chevron-left"
                          data-bs-toggle="tooltip"
                          title="feather-chevron-left"
                        />
                      </li>
                      <li>
                        <ChevronRight
                          className="feather-chevron-right"
                          data-bs-toggle="tooltip"
                          title="feather-chevron-right"
                        />
                      </li>
                      <li>
                        <ChevronUp
                          className="feather-chevron-up"
                          data-bs-toggle="tooltip"
                          title="feather-chevron-up"
                        />
                      </li>
                      <li>
                        <ChevronsDown
                          className="feather-chevrons-down"
                          data-bs-toggle="tooltip"
                          title="feather-chevrons-down"
                        />
                      </li>
                      <li>
                        <ChevronsLeft
                          className="feather-chevrons-left"
                          data-bs-toggle="tooltip"
                          title="feather-chevrons-left"
                        />
                      </li>
                      <li>
                        <ChevronsRight
                          className="feather-chevrons-right"
                          data-bs-toggle="tooltip"
                          title="feather-chevrons-right"
                        />
                      </li>
                      <li>
                        <ChevronsUp
                          className="feather-chevrons-up"
                          data-bs-toggle="tooltip"
                          title="feather-chevrons-up"
                        />
                      </li>
                      <li>
                        <Chrome
                          className="feather-chrome"
                          data-bs-toggle="tooltip"
                          title="feather-chrome"
                        />
                      </li>
                      <li>
                        <Circle
                          className="feather-circle"
                          data-bs-toggle="tooltip"
                          title="feather-circle"
                        />
                      </li>
                      <li>
                        <Clipboard
                          className="feather-clipboard"
                          data-bs-toggle="tooltip"
                          title="feather-clipboard"
                        />
                      </li>
                      <li>
                        <Clock
                          className="feather-clock"
                          data-bs-toggle="tooltip"
                          title="feather-clock"
                        />
                      </li>
                      <li>
                        <Cloud
                          className="feather-cloud"
                          data-bs-toggle="tooltip"
                          title="feather-cloud"
                        />
                      </li>
                      <li>
                        <CloudDrizzle
                          className="feather-cloud-drizzle"
                          data-bs-toggle="tooltip"
                          title="feather-cloud-drizzle"
                        />
                      </li>
                      <li>
                        <CloudLightning
                          className="feather-cloud-lightning"
                          data-bs-toggle="tooltip"
                          title="feather-cloud-lightning"
                        />
                      </li>
                      <li>
                        <CloudOff
                          className="feather-cloud-off"
                          data-bs-toggle="tooltip"
                          title="feather-cloud-off"
                        />
                      </li>
                      <li>
                        <CloudRain
                          className="feather-cloud-rain"
                          data-bs-toggle="tooltip"
                          title="feather-cloud-rain"
                        />
                      </li>
                      <li>
                        <CloudSnow
                          className="feather-cloud-snow"
                          data-bs-toggle="tooltip"
                          title="feather-cloud-snow"
                        />
                      </li>
                      <li>
                        <Code
                          className="feather-code"
                          data-bs-toggle="tooltip"
                          title="feather-code"
                        />
                      </li>
                      <li>
                        <Codepen
                          className="feather-codepen"
                          data-bs-toggle="tooltip"
                          title="feather-codepen"
                        />
                      </li>
                      <li>
                        <Command
                          className="feather-command"
                          data-bs-toggle="tooltip"
                          title="feather-command"
                        />
                      </li>
                      <li>
                        <Compass
                          className="feather-compass"
                          data-bs-toggle="tooltip"
                          title="feather-compass"
                        />
                      </li>
                      <li>
                        <Copy
                          className="feather-copy"
                          data-bs-toggle="tooltip"
                          title="feather-copy"
                        />
                      </li>
                      <li>
                        <CornerDownLeft
                          className="feather-corner-down-left"
                          data-bs-toggle="tooltip"
                          title="feather-corner-down-left"
                        />
                      </li>
                      <li>
                        <CornerDownRight
                          className="feather-corner-down-right"
                          data-bs-toggle="tooltip"
                          title="feather-corner-down-right"
                        />
                      </li>
                      <li>
                        <CornerLeftDown
                          className="feather-corner-left-down"
                          data-bs-toggle="tooltip"
                          title="feather-corner-left-down"
                        />
                      </li>
                      <li>
                        <CornerLeftUp
                          className="feather-corner-left-up"
                          data-bs-toggle="tooltip"
                          title="feather-corner-left-up"
                        />
                      </li>
                      <li>
                        <CornerRightDown
                          className="feather-corner-right-down"
                          data-bs-toggle="tooltip"
                          title="feather-corner-right-down"
                        />
                      </li>
                      <li>
                        <CornerRightUp
                          className="feather-corner-right-up"
                          data-bs-toggle="tooltip"
                          title="feather-corner-right-up"
                        />
                      </li>
                      <li>
                        <CornerUpLeft
                          className="feather-corner-up-left"
                          data-bs-toggle="tooltip"
                          title="feather-corner-up-left"
                        />
                      </li>
                      <li>
                        <CornerUpRight
                          className="feather-corner-up-right"
                          data-bs-toggle="tooltip"
                          title="feather-corner-up-right"
                        />
                      </li>

                      <li>
                        <CreditCard
                          className="feather-credit-card"
                          data-bs-toggle="tooltip"
                          title="feather-credit-card"
                        />
                      </li>
                      <li>
                        <Crop
                          className="feather-crop"
                          data-bs-toggle="tooltip"
                          title="feather-crop"
                        />
                      </li>
                      <li>
                        <Crosshair
                          className="feather-crosshair"
                          data-bs-toggle="tooltip"
                          title="feather-crosshair"
                        />
                      </li>
                      <li>
                        <Database
                          className="feather-database"
                          data-bs-toggle="tooltip"
                          title="feather-database"
                        />
                      </li>
                      <li>
                        <Delete
                          className="feather-delete"
                          data-bs-toggle="tooltip"
                          title="feather-delete"
                        />
                      </li>
                      <li>
                        <Disc
                          className="feather-disc"
                          data-bs-toggle="tooltip"
                          title="feather-disc"
                        />
                      </li>
                      <li>
                        <DollarSign
                          className="feather-dollar-sign"
                          data-bs-toggle="tooltip"
                          title="feather-dollar-sign"
                        />
                      </li>
                      <li>
                        <Download
                          className="feather-download"
                          data-bs-toggle="tooltip"
                          title="feather-download"
                        />
                      </li>
                      <li>
                        <DownloadCloud
                          className="feather-download-cloud"
                          data-bs-toggle="tooltip"
                          title="feather-download-cloud"
                        />
                      </li>
                      <li>
                        <Droplet
                          className="feather-droplet"
                          data-bs-toggle="tooltip"
                          title="feather-droplet"
                        />
                      </li>
                      <li>
                        <Edit
                          className="feather-edit"
                          data-bs-toggle="tooltip"
                          title="feather-edit"
                        />
                      </li>
                      <li>
                        <Edit2
                          className="feather-edit-2"
                          data-bs-toggle="tooltip"
                          title="feather-edit-2"
                        />
                      </li>
                      <li>
                        <Edit3
                          className="feather-edit-3"
                          data-bs-toggle="tooltip"
                          title="feather-edit-3"
                        />
                      </li>
                      <li>
                        <ExternalLink
                          className="feather-external-link"
                          data-bs-toggle="tooltip"
                          title="feather-external-link"
                        />
                      </li>
                      <li>
                        <Eye
                          className="feather-eye"
                          data-bs-toggle="tooltip"
                          title="feather-eye"
                        />
                      </li>
                      <li>
                        <EyeOff
                          className="feather-eye-off"
                          data-bs-toggle="tooltip"
                          title="feather-eye-off"
                        />
                      </li>
                      <li>
                        <Facebook
                          className="feather-facebook"
                          data-bs-toggle="tooltip"
                          title="feather-facebook"
                        />
                      </li>
                      <li>
                        <FastForward
                          className="feather-fast-forward"
                          data-bs-toggle="tooltip"
                          title="feather-fast-forward"
                        />
                      </li>
                      <li>
                        <Feather
                          className="feather-feather"
                          data-bs-toggle="tooltip"
                          title="feather-feather"
                        />
                      </li>
                      <li>
                        <File
                          className="feather-file"
                          data-bs-toggle="tooltip"
                          title="feather-file"
                        />
                      </li>
                      <li>
                        <FileMinus
                          className="feather-file-minus"
                          data-bs-toggle="tooltip"
                          title="feather-file-minus"
                        />
                      </li>
                      <li>
                        <FilePlus
                          className="feather-file-plus"
                          data-bs-toggle="tooltip"
                          title="feather-file-plus"
                        />
                      </li>
                      <li>
                        <FileText
                          className="feather-file-text"
                          data-bs-toggle="tooltip"
                          title="feather-file-text"
                        />
                      </li>
                      <li>
                        <Film
                          className="feather-film"
                          data-bs-toggle="tooltip"
                          title="feather-film"
                        />
                      </li>
                      <li>
                        <Filter
                          className="feather-filter"
                          data-bs-toggle="tooltip"
                          title="feather-filter"
                        />
                      </li>
                      <li>
                        <Flag
                          className="feather-flag"
                          data-bs-toggle="tooltip"
                          title="feather-flag"
                        />
                      </li>
                      <li>
                        <Folder
                          className="feather-folder"
                          data-bs-toggle="tooltip"
                          title="feather-folder"
                        />
                      </li>
                      <li>
                        <FolderMinus
                          className="feather-folder-minus"
                          data-bs-toggle="tooltip"
                          title="feather-folder-minus"
                        />
                      </li>
                      <li>
                        <FolderPlus
                          className="feather-folder-plus"
                          data-bs-toggle="tooltip"
                          title="feather-folder-plus"
                        />
                      </li>
                      <li>
                        <GitBranch
                          className="feather-git-branch"
                          data-bs-toggle="tooltip"
                          title="feather-git-branch"
                        />
                      </li>
                      <li>
                        <GitCommit
                          className="feather-git-commit"
                          data-bs-toggle="tooltip"
                          title="feather-git-commit"
                        />
                      </li>
                      <li>
                        <GitMerge
                          className="feather-git-merge"
                          data-bs-toggle="tooltip"
                          title="feather-git-merge"
                        />
                      </li>
                      <li>
                        <GitPullRequest
                          className="feather-git-pull-request"
                          data-bs-toggle="tooltip"
                          title="feather-git-pull-request"
                        />
                      </li>
                      <li>
                        <GitHub
                          className="feather-github"
                          data-bs-toggle="tooltip"
                          title="feather-github"
                        />
                      </li>
                      <li>
                        <Gitlab
                          className="feather-gitlab"
                          data-bs-toggle="tooltip"
                          title="feather-gitlab"
                        />
                      </li>
                      <li>
                        <Globe
                          className="feather-globe"
                          data-bs-toggle="tooltip"
                          title="feather-globe"
                        />
                      </li>
                      <li>
                        <Grid
                          className="feather-grid"
                          data-bs-toggle="tooltip"
                          title="feather-grid"
                        />
                      </li>
                      <li>
                        <HardDrive
                          className="feather-hard-drive"
                          data-bs-toggle="tooltip"
                          title="feather-hard-drive"
                        />
                      </li>
                      <li>
                        <Hash
                          className="feather-hash"
                          data-bs-toggle="tooltip"
                          title="feather-hash"
                        />
                      </li>
                      <li>
                        <Headphones
                          className="feather-headphones"
                          data-bs-toggle="tooltip"
                          title="feather-headphones"
                        />
                      </li>
                      <li>
                        <Heart
                          className="feather-heart"
                          data-bs-toggle="tooltip"
                          title="feather-heart"
                        />
                      </li>
                      <li>
                        <HelpCircle
                          className="feather-help-circle"
                          data-bs-toggle="tooltip"
                          title="feather-help-circle"
                        />
                      </li>
                      <li>
                        <Home
                          className="feather-home"
                          data-bs-toggle="tooltip"
                          title="feather-home"
                        />
                      </li>
                      <li>
                        <Image
                          className="feather-image"
                          data-bs-toggle="tooltip"
                          title="feather-image"
                        />
                      </li>
                      <li>
                        <Inbox
                          className="feather-inbox"
                          data-bs-toggle="tooltip"
                          title="feather-inbox"
                        />
                      </li>
                      <li>
                        <Info
                          className="feather-info"
                          data-bs-toggle="tooltip"
                          title="feather-info"
                        />
                      </li>
                      <li>
                        <Instagram
                          className="feather-instagram"
                          data-bs-toggle="tooltip"
                          title="feather-instagram"
                        />
                      </li>
                      <li>
                        <Italic
                          className="feather-italic"
                          data-bs-toggle="tooltip"
                          title="feather-italic"
                        />
                      </li>
                      <li>
                        <Layers
                          className="feather-layers"
                          data-bs-toggle="tooltip"
                          title="feather-layers"
                        />
                      </li>
                      <li>
                        <Layout
                          className="feather-layout"
                          data-bs-toggle="tooltip"
                          title="feather-layout"
                        />
                      </li>
                      <li>
                        <LifeBuoy
                          className="feather-life-buoy"
                          data-bs-toggle="tooltip"
                          title="feather-life-buoy"
                        />
                      </li>
                      <li>
                        <Link
                          className="feather-link"
                          data-bs-toggle="tooltip"
                          title="feather-link"
                        />
                      </li>
                      <li>
                        <Link2
                          className="feather-link-2"
                          data-bs-toggle="tooltip"
                          title="feather-link-2"
                        />
                      </li>

                      <li>
                        <List
                          className="feather-list"
                          data-bs-toggle="tooltip"
                          title="feather-list"
                        />
                      </li>
                      <li>
                        <Loader
                          className="feather-loader"
                          data-bs-toggle="tooltip"
                          title="feather-loader"
                        />
                      </li>
                      <li>
                        <Lock
                          className="feather-lock"
                          data-bs-toggle="tooltip"
                          title="feather-lock"
                        />
                      </li>
                      <li>
                        <LogIn
                          className="feather-log-in"
                          data-bs-toggle="tooltip"
                          title="feather-log-in"
                        />
                      </li>
                      <li>
                        <LogOut
                          className="feather-log-out"
                          data-bs-toggle="tooltip"
                          title="feather-log-out"
                        />
                      </li>
                      <li>
                        <Mail
                          className="feather-mail"
                          data-bs-toggle="tooltip"
                          title="feather-mail"
                        />
                      </li>
                      <li>
                        <Map
                          className="feather-map"
                          data-bs-toggle="tooltip"
                          title="feather-map"
                        />
                      </li>
                      <li>
                        <MapPin
                          className="feather-map-pin"
                          data-bs-toggle="tooltip"
                          title="feather-map-pin"
                        />
                      </li>
                      <li>
                        <Maximize
                          className="feather-maximize"
                          data-bs-toggle="tooltip"
                          title="feather-maximize"
                        />
                      </li>
                      <li>
                        <Maximize2
                          className="feather-maximize-2"
                          data-bs-toggle="tooltip"
                          title="feather-maximize-2"
                        />
                      </li>
                      <li>
                        <Menu
                          className="feather-menu"
                          data-bs-toggle="tooltip"
                          title="feather-menu"
                        />
                      </li>
                      <li>
                        <MessageCircle
                          className="feather-message-circle"
                          data-bs-toggle="tooltip"
                          title="feather-message-circle"
                        />
                      </li>
                      <li>
                        <MessageSquare
                          className="feather-message-square"
                          data-bs-toggle="tooltip"
                          title="feather-message-square"
                        />
                      </li>
                      <li>
                        <Mic
                          className="feather-mic"
                          data-bs-toggle="tooltip"
                          title="feather-mic"
                        />
                      </li>
                      <li>
                        <MicOff
                          className="feather-mic-off"
                          data-bs-toggle="tooltip"
                          title="feather-mic-off"
                        />
                      </li>
                      <li>
                        <Minimize
                          className="feather-minimize"
                          data-bs-toggle="tooltip"
                          title="feather-minimize"
                        />
                      </li>
                      <li>
                        <Minimize2
                          className="feather-minimize-2"
                          data-bs-toggle="tooltip"
                          title="feather-minimize-2"
                        />
                      </li>
                      <li>
                        <Minus
                          className="feather-minus"
                          data-bs-toggle="tooltip"
                          title="feather-minus"
                        />
                      </li>
                      <li>
                        <MinusCircle
                          className="feather-minus-circle"
                          data-bs-toggle="tooltip"
                          title="feather-minus-circle"
                        />
                      </li>
                      <li>
                        <MinusSquare
                          className="feather-minus-square"
                          data-bs-toggle="tooltip"
                          title="feather-minus-square"
                        />
                      </li>
                      <li>
                        <Monitor
                          className="feather-monitor"
                          data-bs-toggle="tooltip"
                          title="feather-monitor"
                        />
                      </li>
                      <li>
                        <Moon
                          className="feather-moon"
                          data-bs-toggle="tooltip"
                          title="feather-moon"
                        />
                      </li>
                      <li>
                        <MoreHorizontal
                          className="feather-more-horizontal"
                          data-bs-toggle="tooltip"
                          title="feather-more-horizontal"
                        />
                      </li>
                      <li>
                        <MoreVertical
                          className="feather-more-vertical"
                          data-bs-toggle="tooltip"
                          title="feather-more-vertical"
                        />
                      </li>
                      <li>
                        <Move
                          className="feather-move"
                          data-bs-toggle="tooltip"
                          title="feather-move"
                        />
                      </li>
                      <li>
                        <Music
                          className="feather-music"
                          data-bs-toggle="tooltip"
                          title="feather-music"
                        />
                      </li>
                      <li>
                        <Navigation
                          className="feather-navigation"
                          data-bs-toggle="tooltip"
                          title="feather-navigation"
                        />
                      </li>
                      <li>
                        <Navigation2
                          className="feather-navigation-2"
                          data-bs-toggle="tooltip"
                          title="feather-navigation-2"
                        />
                      </li>
                      <li>
                        <Octagon
                          className="feather-octagon"
                          data-bs-toggle="tooltip"
                          title="feather-octagon"
                        />
                      </li>
                      <li>
                        <Package
                          className="feather-package"
                          data-bs-toggle="tooltip"
                          title="feather-package"
                        />
                      </li>
                      <li>
                        <Paperclip
                          className="feather-paperclip"
                          data-bs-toggle="tooltip"
                          title="feather-paperclip"
                        />
                      </li>
                      <li>
                        <Pause
                          className="feather-pause"
                          data-bs-toggle="tooltip"
                          title="feather-pause"
                        />
                      </li>
                      <li>
                        <PauseCircle
                          className="feather-pause-circle"
                          data-bs-toggle="tooltip"
                          title="feather-pause-circle"
                        />
                      </li>
                      <li>
                        <Percent
                          className="feather-percent"
                          data-bs-toggle="tooltip"
                          title="feather-percent"
                        />
                      </li>
                      <li>
                        <Phone
                          className="feather-phone"
                          data-bs-toggle="tooltip"
                          title="feather-phone"
                        />
                      </li>
                      <li>
                        <PhoneCall
                          className="feather-phone-call"
                          data-bs-toggle="tooltip"
                          title="feather-phone-call"
                        />
                      </li>
                      <li>
                        <PhoneForwarded
                          className="feather-phone-forwarded"
                          data-bs-toggle="tooltip"
                          title="feather-phone-forwarded"
                        />
                      </li>
                      <li>
                        <PhoneIncoming
                          className="feather-phone-incoming"
                          data-bs-toggle="tooltip"
                          title="feather-phone-incoming"
                        />
                      </li>
                      <li>
                        <PhoneMissed
                          className="feather-phone-missed"
                          data-bs-toggle="tooltip"
                          title="feather-phone-missed"
                        />
                      </li>
                      <li>
                        <PhoneOff
                          className="feather-phone-off"
                          data-bs-toggle="tooltip"
                          title="feather-phone-off"
                        />
                      </li>
                      <li>
                        <PhoneOutgoing
                          className="feather-phone-outgoing"
                          data-bs-toggle="tooltip"
                          title="feather-phone-outgoing"
                        />
                      </li>
                      <li>
                        <PieChart
                          className="feather-pie-chart"
                          data-bs-toggle="tooltip"
                          title="feather-pie-chart"
                        />
                      </li>
                      <li>
                        <Play
                          className="feather-play"
                          data-bs-toggle="tooltip"
                          title="feather-play"
                        />
                      </li>
                      <li>
                        <PlayCircle
                          className="feather-play-circle"
                          data-bs-toggle="tooltip"
                          title="feather-play-circle"
                        />
                      </li>
                      <li>
                        <Plus
                          className="feather-plus"
                          data-bs-toggle="tooltip"
                          title="feather-plus"
                        />
                      </li>
                      <li>
                        <PlusCircle
                          className="feather-plus-circle"
                          data-bs-toggle="tooltip"
                          title="feather-plus-circle"
                        />
                      </li>
                      <li>
                        <PlusSquare
                          className="feather-plus-circle"
                          data-bs-toggle="tooltip"
                          title="feather-plus-circle"
                        />
                      </li>
                      <li>
                        <Pocket
                          className="feather-pocket"
                          data-bs-toggle="tooltip"
                          title="feather-pocket"
                        />
                      </li>
                      <li>
                        <Power
                          className="feather-power"
                          data-bs-toggle="tooltip"
                          title="feather-power"
                        />
                      </li>
                      <li>
                        <Printer
                          className="feather-printer"
                          data-bs-toggle="tooltip"
                          title="feather-printer"
                        />
                      </li>
                      <li>
                        <Radio
                          className="feather-radio"
                          data-bs-toggle="tooltip"
                          title="feather-radio"
                        />
                      </li>
                      <li>
                        <RefreshCcw
                          className="feather-refresh-ccw"
                          data-bs-toggle="tooltip"
                          title="feather-refresh-ccw"
                        />
                      </li>
                      <li>
                        <RefreshCw
                          className="feather-refresh-cw"
                          data-bs-toggle="tooltip"
                          title="feather-refresh-cw"
                        />
                      </li>
                      <li>
                        <Repeat
                          className="feather-repeat"
                          data-bs-toggle="tooltip"
                          title="feather-repeat"
                        />
                      </li>
                      <li>
                        <Rewind
                          className="feather-rewind"
                          data-bs-toggle="tooltip"
                          title="feather-rewind"
                        />
                      </li>
                      <li>
                        <RotateCcw
                          className="feather-rotate-ccw"
                          data-bs-toggle="tooltip"
                          title="feather-rotate-ccw"
                        />
                      </li>
                      <li>
                        <RotateCw
                          className="feather-rotate-cw"
                          data-bs-toggle="tooltip"
                          title="feather-rotate-cw"
                        />
                      </li>
                      <li>
                        <Rss
                          className="feather-rss"
                          data-bs-toggle="tooltip"
                          title="feather-rss"
                        />
                      </li>
                      <li>
                        <Save
                          className="feather-save"
                          data-bs-toggle="tooltip"
                          title="feather-save"
                        />
                      </li>
                      <li>
                        <Scissors
                          className="feather-scissors"
                          data-bs-toggle="tooltip"
                          title="feather-scissors"
                        />
                      </li>
                      <li>
                        <Search
                          className="feather-search"
                          data-bs-toggle="tooltip"
                          title="feather-search"
                        />
                      </li>
                      <li>
                        <Send
                          className="feather-send"
                          data-bs-toggle="tooltip"
                          title="feather-send"
                        />
                      </li>
                      <li>
                        <Server
                          className="feather-server"
                          data-bs-toggle="tooltip"
                          title="feather-server"
                        />
                      </li>
                      <li>
                        <Settings
                          className="feather-settings"
                          data-bs-toggle="tooltip"
                          title="feather-settings"
                        />
                      </li>
                      <li>
                        <Share
                          className="feather-share"
                          data-bs-toggle="tooltip"
                          title="feather-share"
                        />
                      </li>
                      <li>
                        <Share2
                          className="feather-share-2"
                          data-bs-toggle="tooltip"
                          title="feather-share-2"
                        />
                      </li>
                      <li>
                        <Shield
                          className="feather-shield"
                          data-bs-toggle="tooltip"
                          title="feather-shield"
                        />
                      </li>
                      <li>
                        <ShieldOff
                          className="feather-shield-off"
                          data-bs-toggle="tooltip"
                          title="feather-shield-off"
                        />
                      </li>
                      <li>
                        <ShoppingBag
                          className="feather-shopping-bag"
                          data-bs-toggle="tooltip"
                          title="feather-shopping-bag"
                        />
                      </li>
                      <li>
                        <ShoppingCart
                          className="feather-shopping-cart"
                          data-bs-toggle="tooltip"
                          title="feather-shopping-cart"
                        />
                      </li>
                      <li>
                        <Shuffle
                          className="feather-shuffle"
                          data-bs-toggle="tooltip"
                          title="feather-shuffle"
                        />
                      </li>
                      <li>
                        <Sidebar
                          className="feather-sidebar"
                          data-bs-toggle="tooltip"
                          title="feather-sidebar"
                        />
                      </li>
                      <li>
                        <SkipBack
                          className="feather-skip-back"
                          data-bs-toggle="tooltip"
                          title="feather-skip-back"
                        />
                      </li>
                      <li>
                        <SkipForward
                          className="feather-skip-forward"
                          data-bs-toggle="tooltip"
                          title="feather-skip-forward"
                        />
                      </li>
                      <li>
                        <Slack
                          className="feather-slack"
                          data-bs-toggle="tooltip"
                          title="feather-slack"
                        />
                      </li>
                      <li>
                        <Slash
                          className="feather-slash"
                          data-bs-toggle="tooltip"
                          title="feather-slash"
                        />
                      </li>
                      <li>
                        <Sliders
                          className="feather-sliders"
                          data-bs-toggle="tooltip"
                          title="feather-sliders"
                        />
                      </li>
                      <li>
                        <Smartphone
                          className="feather-smartphone"
                          data-bs-toggle="tooltip"
                          title="feather-smartphone"
                        />
                      </li>
                      <li>
                        <Speaker
                          className="feather-speaker"
                          data-bs-toggle="tooltip"
                          title="feather-speaker"
                        />
                      </li>
                      <li>
                        <Square
                          className="feather-square"
                          data-bs-toggle="tooltip"
                          title="feather-square"
                        />
                      </li>
                      <li>
                        <Star
                          className="feather-star"
                          data-bs-toggle="tooltip"
                          title="feather-star"
                        />
                      </li>
                      <li>
                        <StopCircle
                          className="feather-stop-circle"
                          data-bs-toggle="tooltip"
                          title="feather-stop-circle"
                        />
                      </li>
                      <li>
                        <Sun
                          className="feather-sun"
                          data-bs-toggle="tooltip"
                          title="feather-sun"
                        />
                      </li>
                      <li>
                        <Sunrise
                          className="feather-sunrise"
                          data-bs-toggle="tooltip"
                          title="feather-sunrise"
                        />
                      </li>
                      <li>
                        <Sunset
                          className="feather-sunset"
                          data-bs-toggle="tooltip"
                          title="feather-sunset"
                        />
                      </li>
                      <li>
                        <Tablet
                          className="feather-tablet"
                          data-bs-toggle="tooltip"
                          title="feather-tablet"
                        />
                      </li>
                      <li>
                        <Tag
                          className="feather-tag"
                          data-bs-toggle="tooltip"
                          title="feather-tag"
                        />
                      </li>
                      <li>
                        <Target
                          className="feather-target"
                          data-bs-toggle="tooltip"
                          title="feather-target"
                        />
                      </li>
                      <li>
                        <Terminal
                          className="feather-terminal"
                          data-bs-toggle="tooltip"
                          title="feather-terminal"
                        />
                      </li>
                      <li>
                        <Thermometer
                          className="feather-thermometer"
                          data-bs-toggle="tooltip"
                          title="feather-thermometer"
                        />
                      </li>
                      <li>
                        <ThumbsDown
                          className="feather-thumbs-down"
                          data-bs-toggle="tooltip"
                          title="feather-thumbs-down"
                        />
                      </li>
                      <li>
                        <ThumbsUp
                          className="feather-thumbs-up"
                          data-bs-toggle="tooltip"
                          title="feather-thumbs-up"
                        />
                      </li>
                      <li>
                        <ToggleLeft
                          className="feather-toggle-left"
                          data-bs-toggle="tooltip"
                          title="feather-toggle-left"
                        />
                      </li>
                      <li>
                        <ToggleRight
                          className="feather-toggle-right"
                          data-bs-toggle="tooltip"
                          title="feather-toggle-right"
                        />
                      </li>
                      <li>
                        <Trash
                          className="feather-trash"
                          data-bs-toggle="tooltip"
                          title="feather-trash"
                        />
                      </li>
                      <li>
                        <Trash2
                          className="feather-trash-2"
                          data-bs-toggle="tooltip"
                          title="feather-trash-2"
                        />
                      </li>
                      <li>
                        <TrendingDown
                          className="feather-trending-down"
                          data-bs-toggle="tooltip"
                          title="feather-trending-down"
                        />
                      </li>
                      <li>
                        <TrendingUp
                          className="feather-trending-up"
                          data-bs-toggle="tooltip"
                          title="feather-trending-up"
                        />
                      </li>
                      <li>
                        <Triangle
                          className="feather-triangle"
                          data-bs-toggle="tooltip"
                          title="feather-triangle"
                        />
                      </li>
                      <li>
                        <Truck
                          className="feather-truck"
                          data-bs-toggle="tooltip"
                          title="feather-truck"
                        />
                      </li>
                      <li>
                        <Tv
                          className="feather-tv"
                          data-bs-toggle="tooltip"
                          title="feather-tv"
                        />
                      </li>
                      <li>
                        <Twitter
                          className="feather-twitter"
                          data-bs-toggle="tooltip"
                          title="feather-twitter"
                        />
                      </li>
                      <li>
                        <Type
                          className="feather-type"
                          data-bs-toggle="tooltip"
                          title="feather-type"
                        />
                      </li>
                      <li>
                        <Umbrella
                          className="feather-umbrella"
                          data-bs-toggle="tooltip"
                          title="feather-umbrella"
                        />
                      </li>
                      <li>
                        <Underline
                          className="feather-underline"
                          data-bs-toggle="tooltip"
                          title="feather-underline"
                        />
                      </li>
                      <li>
                        <Unlock
                          className="feather-unlock"
                          data-bs-toggle="tooltip"
                          title="feather-unlock"
                        />
                      </li>
                      <li>
                        <Upload
                          className="feather-upload"
                          data-bs-toggle="tooltip"
                          title="feather-upload"
                        />
                      </li>
                      <li>
                        <UploadCloud
                          className="feather-upload-cloud"
                          data-bs-toggle="tooltip"
                          title="feather-upload-cloud"
                        />
                      </li>
                      <li>
                        <User
                          className="feather-user"
                          data-bs-toggle="tooltip"
                          title="feather-user"
                        />
                      </li>
                      <li>
                        <UserCheck
                          className="feather-user-check"
                          data-bs-toggle="tooltip"
                          title="feather-user-check"
                        />
                      </li>
                      <li>
                        <UserMinus
                          className="feather-user-minus"
                          data-bs-toggle="tooltip"
                          title="feather-user-minus"
                        />
                      </li>
                      <li>
                        <UserPlus
                          className="feather-user-plus"
                          data-bs-toggle="tooltip"
                          title="feather-user-plus"
                        />
                      </li>
                      <li>
                        <UserX
                          className="feather-user-x"
                          data-bs-toggle="tooltip"
                          title="feather-user-x"
                        />
                      </li>
                      <li>
                        <Users
                          className="feather-users"
                          data-bs-toggle="tooltip"
                          title="feather-users"
                        />
                      </li>
                      <li>
                        <Video
                          className="feather-video"
                          data-bs-toggle="tooltip"
                          title="feather-video"
                        />
                      </li>
                      <li>
                        <VideoOff
                          className="feather-video-off"
                          data-bs-toggle="tooltip"
                          title="feather-video-off"
                        />
                      </li>
                      <li>
                        <Voicemail
                          className="feather-voicemail"
                          data-bs-toggle="tooltip"
                          title="feather-voicemail"
                        />
                      </li>
                      <li>
                        <Volume
                          className="feather-volume"
                          data-bs-toggle="tooltip"
                          title="feather-volume"
                        />
                      </li>
                      <li>
                        <Volume1
                          className="feather-volume-1"
                          data-bs-toggle="tooltip"
                          title="feather-volume-1"
                        />
                      </li>
                      <li>
                        <Volume2
                          className="feather-volume-2"
                          data-bs-toggle="tooltip"
                          title="feather-volume-2"
                        />
                      </li>
                      <li>
                        <VolumeX
                          className="feather-volume-x"
                          data-bs-toggle="tooltip"
                          title="feather-volume-x"
                        />
                      </li>
                      <li>
                        <Watch
                          className="feather-watch"
                          data-bs-toggle="tooltip"
                          title="feather-watch"
                        />
                      </li>
                      <li>
                        <Wifi
                          className="feather-wifi"
                          data-bs-toggle="tooltip"
                          title="feather-wifi"
                        />
                      </li>
                      <li>
                        <WifiOff
                          className="feather-wifi-off"
                          data-bs-toggle="tooltip"
                          title="feather-wifi-off"
                        />
                      </li>
                      <li>
                        <Wind
                          className="feather-wind"
                          data-bs-toggle="tooltip"
                          title="feather-wind"
                        />
                      </li>
                      <li>
                        <X
                          className="feather-x"
                          data-bs-toggle="tooltip"
                          title="feather-x"
                        />
                      </li>
                      <li>
                        <XCircle
                          className="feather-x-circle"
                          data-bs-toggle="tooltip"
                          title="feather-x-circle"
                        />
                      </li>
                      <li>
                        <XSquare
                          className="feather-x-square"
                          data-bs-toggle="tooltip"
                          title="feather-x-square"
                        />
                      </li>
                      <li>
                        <Zap
                          className="feather-zap"
                          data-bs-toggle="tooltip"
                          title="feather-zap"
                        />
                      </li>
                      <li>
                        <ZapOff
                          className="feather-zap-off"
                          data-bs-toggle="tooltip"
                          title="feather-zap-off"
                        />
                      </li>
                      <li>
                        <ZoomIn
                          className="feather-zoom-in"
                          data-bs-toggle="tooltip"
                          title="feather-zoom-in"
                        />
                      </li>
                      <li>
                        <ZoomOut
                          className="feather-zoom-out"
                          data-bs-toggle="tooltip"
                          title="feather-zoom-out"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* /Chart */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatherIcons;
