/* eslint-disable no-unused-expressions */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import {
  Avatar_02,
} from "../../../Routes/ImagePath";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { AdminLeaveAddModelPopup } from "../../../components/modelpopup/AdminLeaveModelPopup";
import LeaveFilter from "../../../components/LeaveFilter";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import { LEAVES } from "../../../services/ENDPOINTS";
import { useLazyGetLeavesQuery } from "../../../services/hr.service";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useGetLeaveTypesQuery } from "../../../services/settings.service";

const AdminLeave = () => {
  const [selected, setSelected] = useState(null);
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({});
  const [results, setResult] = useState([]);
  const dispatch = useDispatch();
  const {data: leaveTypesData} = useGetLeaveTypesQuery();

  const [getResults, {data, isFetching, error}] = useLazyGetLeavesQuery();

  useEffect(() => {
    getResults({page: 1, search});
  }, []);

  useEffect(() => {
    if (data) {
      setResult(data?.data?.result || []);
      setPagination(data?.data.meta);
    }
  }, [data]);

  const doDelete = (e, item) => {
    // e.currentTarget.closest('tr').remove()
    dispatch(
      toggleDelete({
        open: true,
        title: 'Leave',
        url: `${LEAVES}/${item.id}`,
        ele: e.currentTarget,
        parent: 'tr'
      })
    );
  }

  const columns = [
    {
      title: "Employee",
      dataIndex: "name",
      render: (text, record) => (
        <h2 className="table-avatar">
          <Link to={`/profile/${record.employee.id}`} className="avatar">
            <img
              alt=""
              src={
                record.emp_code
                  ? `http://129.18.184.239:8091/auth_files/photo/${record.emp_code}.jpg`
                  : Avatar_02
              }
            />
          </Link>
          <Link to={`/employee/${record.employee.id}`}>
            {`${record.employee.first_name} ${record.employee.last_name}`}
          </Link>
        </h2>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      title: "Leave Type",
      dataIndex: "leavetype",
      render: (text, record) => record.leave_type.name,
      // sorter: (a, b) => a.leavetype.length - b.leavetype.length,
    },

    {
      title: "From",
      dataIndex: "start_date",
      render: (text) => <span>{dayjs(text).format('DD MMM YYYY')}</span>,
      // sorter: (a, b) => a.from.length - b.from.length,
    },
    {
      title: "To",
      dataIndex: "end_date",
      render: (text) => <span>{dayjs(text).format('DD MMM YYYY')}</span>,

      sorter: (a, b) => a.to.length - b.to.length,
    },

    {
      title: "No Of Days",
      dataIndex: "noofdays",
      render: (text) => <span>{text}</span>,

      sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },

    {
      title: "Reason",
      dataIndex: "reason",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.reason.length - b.reason.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <div className="dropdown action-label text-center">
          <Link
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            to="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text === "New"
                  ? "far fa-dot-circle text-purple"
                  : text === "Pending"
                  ? "far fa-dot-circle text-info"
                  : text === "Approved"
                  ? "far fa-dot-circle text-success"
                  : "far fa-dot-circle text-danger"
              }
            />{" "}
            {text}
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link className="dropdown-item" to="#">
              <i className="far fa-dot-circle text-purple" /> New
            </Link>
            <Link className="dropdown-item" to="#">
              <i className="far fa-dot-circle text-info" /> Pending
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#approve_leave"
            >
              <i className="far fa-dot-circle text-success" /> Approved
            </Link>
            <Link className="dropdown-item" to="#">
              <i className="far fa-dot-circle text-danger" /> Declined
            </Link>
          </div>
        </div>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Action",
      render: (t, r) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#leave_modal"
              onClick={() => setSelected(r)}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={(e) => doDelete(e, r)}
            >
              <i className="fa-regular fa-trash-can m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
      sorter: true,
    },
  ];


  const statsData = [
    {
      id: 1,
      title: "Today Presents",
      value: "12 / 60",
    },
    {
      id: 2,
      title: "Planned Leaves",
      value: "8",
      subText: "Today",
    },
    {
      id: 3,
      title: "Unplanned Leaves",
      value: "0",
      subText: "Today",
    },
    {
      id: 4,
      title: "Pending Requests",
      value: "12",
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Manage Leaves"
            title="Dashboard"
            subtitle="Leaves"
            modal="#leave_modal"
            name="Add Leave"
          />
          {/* /Page Header */}
          {/* Leave Statistics */}
          <div className="row">
            {statsData.map((stat, index) => (
              <div className="col-md-3 d-flex" key={index}>
                <div className="stats-info w-100">
                  <h6>{stat.title}</h6>
                  {stat.subText ? (
                    <h4>
                      {stat.value} <span>{stat.subText}</span>
                    </h4>
                  ) : (
                    <h4>{stat.value}</h4>
                  )}
                </div>
              </div>
            ))}
          </div>
          <LeaveFilter leavesTypes={leaveTypesData?.data} onSearch={(filter) => getResults({filter, page: 1})} />
          {/* /Leave Statistics */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                {/* <SearchBox /> */}
                <Table
                  className="table-striped"
                  columns={columns}
                  loading={isFetching}
                  dataSource={results}
                  rowKey={(record) => record.id}
                  pagination={{ 
                    defaultPageSize: 100, 
                    total: pagination?.total,
                    onChange: (page, pageSize) => {
                      getResults({page, search});
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        {/* Add Leave Modal */}
        <AdminLeaveAddModelPopup leaveTypes={leaveTypesData?.data} item={selected} />
        {/* /Add Leave Modal */}

        
      </div>
    </>
  );
};

export default AdminLeave;
