/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Avatar_02 } from "../../../Routes/ImagePath/index";
import { Table } from "antd";
import PromotionModal from "../../../components/modelpopup/PromotionModal";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useDispatch } from "react-redux";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import { PROMOTIONS } from "../../../services/ENDPOINTS";
import { useLazyGetPromotionsQuery } from "../../../services/hr.service";
import { FilterBox } from "../../../components/FilterBox";
import dayjs from "dayjs";

const Promotion = () => {
  const [selected, setSelected] = useState(null);
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({});
  const [results, setResult] = useState([]);
  const dispatch = useDispatch();

  const [getResults, {data, isFetching, error}] = useLazyGetPromotionsQuery();

  useEffect(() => {
    getResults({page: 1, search});
  }, []);

  useEffect(() => {
    if (data) {
      setResult(data?.data?.result || []);
      setPagination(data?.data.meta);
    }
  }, [data]);

  const doDelete = (e, item) => {
    // e.currentTarget.closest('tr').remove()
    dispatch(
      toggleDelete({
        open: true,
        title: 'Promotion',
        url: `${PROMOTIONS}/${item.id}`,
        ele: e.currentTarget,
        parent: 'tr'
      })
    );
  }


  const columns = [
    {
      title: "",
      sorter: "true",
      render: (t, r) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              onClick={(e) => setSelected(record)}
              data-bs-toggle="modal"
              data-bs-target="#promotion_modal"
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={(e) => doDelete(e, record)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
    {
      title: "Promoted Employee",
      dataIndex: "name",
      render: (text, record) => (
        <h2 className="table-avatar">
          <Link to={`/profile/${record.employee.id}`} className="avatar">
            <img
              alt=""
              src={
                record.emp_code
                  ? `http://129.18.184.239:8091/auth_files/photo/${record.emp_code}.jpg`
                  : Avatar_02
              }
            />
          </Link>
          <Link to={`/employee/${record.employee.id}`}>
            {`${record.employee.first_name} ${record.employee.last_name}`}
          </Link>
        </h2>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Department",
      dataIndex: "department",
      render: (text, record) => record.department?.name,
    },

    {
      title: "Promotion Designation From",
      dataIndex: "promotiondesignationfrom",
      render: (text, record) => record.prev_designation?.name,
    },

    {
      title: "Promotion Designation To",
      dataIndex: "promotiondesignationto",
      render: (text, record) => record.new_designation?.name,
    },
    {
      title: "Promotion Date",
      dataIndex: "promotion_date",
      sorter: (a, b) => a.promotion_date.length - b.promotion_date.length,
      render: (text, record) =>
        dayjs(record.promotion_date).format('DD MMM YYYY'),
    },
    {
      title: "Action",
      sorter: "true",
      render: () => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              onClick={(e) => setSelected(record)}
              data-bs-toggle="modal"
              data-bs-target="#promotion_modal"
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={(e) => doDelete(e, record)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Manage Promotion"
            title="Dashboard"
            subtitle="Promotion"
            modal="#promotion_modal"
            name="Add New"
          />
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <FilterBox 
                  getResults={(val) => {
                    setSearch(val);
                    getResults({page: 1, search: val});
                  }} 
                />
                {/* Promotion Table */}
                <Table
                  className="table-striped"
                  columns={columns}
                  loading={isFetching}
                  dataSource={results}
                  rowKey={(record) => record.id}
                  pagination={{ 
                    defaultPageSize: 100, 
                    total: pagination?.total,
                    onChange: (page, pageSize) => {
                      getResults({page, search});
                    },
                  }}
                />
                {/* /Promotion Table */}
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      <PromotionModal item={selected} />
    </>
  );
};

export default Promotion;
