
import { createApi } from "@reduxjs/toolkit/query/react";
import customBaseQuery from "../custom-query/customQuery";

const apiSlice = createApi({
  reducerPath: "hrApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Users", "Employees", "Employee", "Branches", "Leaves", "LeaveTypes", 
    "Departments", "Designations", "TerminationTypes", "Terminations", 
    "Resignations", "Promotions", "Transfers", "Loans"
  ],
  endpoints: (builder) => ({}),
  // refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 50000,
});

export default apiSlice;
