import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {Select} from "antd";
import { useLazySearchEmployeesQuery } from "../../services/employee.service";
import { useSaveLeavesMutation } from "../../services/hr.service";
import { useForm, Controller } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import { customStyles } from "../../utils";
import alertify from "alertifyjs";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { LeaveSchema } from "../../utils/validationSchema";
import dayjs from "dayjs";

const { Option } = Select;


export const AdminLeaveAddModelPopup = ({leaveTypes, item}) => {
  const {user} = useSelector((state) => state.user);

  const branch_id = (!['HR Admin', 'Admin', 'HR Assistant'].includes(user?.role?.name)) ? user?.branch?.id : ''
  const [employees, setEmployees] = useState([]);
  const [searchEmployees, {data: searchData, error: searchError}] = useLazySearchEmployeesQuery();
  const [saveResignation, {data, isLoading, isSuccess, error}] = useSaveLeavesMutation();
  const [noOfDays, setNoOfDays] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LeaveSchema),
  });

  useEffect(() => {
    if (searchData) {
      setEmployees(searchData?.data)
    }
  }, [searchData, searchError])

  useEffect(() => {
    if (isSuccess && data?.success){
      alertify.success(data?.message);
      document.getElementById('close-modal').click();
      setValue('leave_type_id', "")
      setValue('employee_id', "");
      setValue('start_date', "");
      setValue('end_date', "");
      setValue('reason', "");
      setValue('id', "");
    }

    if (data && !data.success)
      alertify.error(data.errors);

    if (error) 
      alertify.error(error?.message);

  }, [data, error, isSuccess]);

  useEffect(() => {
    if (startDate !== '' && endDate !== '') {
      const date1 = dayjs(endDate)
      setNoOfDays(date1.diff(startDate, 'days'))
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (item) {
      setEmployees([...employees, {
        id: item.employee.id,
        name: item.employee.first_name + ' ' + item.employee.last_name,
        designation: item.employee.designation,
        department: item.department
      }]);
      setValue('leave_type_id', item.leave_type.id)
      setValue('employee_id', item.employee.id);
      setValue('reason', item.reason);
      setValue('start_date', dayjs(item.start_date).toDate());
      setValue('end_date', dayjs(item.end_date).toDate());
      setValue('id', item.id);
    }
  }, [item]);

  const findEmployees = (value) => {
    if (value) {
      searchEmployees({q:value, branch_id});
    } else {
      setEmployees([]);
    }
  }

  const save = payload => {
    payload.start_date = dayjs(payload.start_date).format('YYYY-MM-DD');
    payload.end_date = dayjs(payload.end_date).format('YYYY-MM-DD');
    saveResignation(payload);
  }

  return (
    <>
      <div id="leave_modal" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{item ? 'Edit' : 'Add'} Leave</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="close-modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(save)}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Employee <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="employee_id"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        showSearch
                        size="large"
                        value={value}
                        style={{width: '100%'}}
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        onSearch={findEmployees}
                        onChange={onChange}
                        notFoundContent={null}
                      >
                        {employees.map(d => <Option key={d.id} value={d.id}>{d.name}</Option>)}
                      </Select>
                    )}
                  />
                  <span className="text-danger">
                      {errors?.employee_id?.message}
                  </span>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Type <span className="text-danger">*</span>
                  </label>
                  <div className="add-group-btn">
                    <Controller
                      name="leave_type_id"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Select
                          size="large"
                          value={value}
                          placeholder="Select "
                          styles={customStyles}
                          style={{width: '100%'}}
                          onChange={onChange}
                        >
                          {leaveTypes?.map(d => <Option key={d.id} value={d.id}>{d.name}</Option>)}
                        </Select>
                      )}
                    />
                    <span className="text-danger">
                      {errors?.termination_type_id?.message}
                  </span>
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    From <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <Controller
                      name="start_date"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <DatePicker
                          selected={value || null}
                          onChange={(value) => {
                            onChange(value);
                            setStartDate(value)
                          }}
                          className="form-control floating datetimepicker"
                          type="date"
                          dateFormat="dd-MM-yyyy"
                        />
                      )}
                    />
                    <span className="text-danger">
                      {errors?.start_date?.message}
                    </span>
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    To <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <Controller
                      name="end_date"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <DatePicker
                          selected={value || null}
                          onChange={(value) => {
                            onChange(value);
                            setEndDate(value);
                          }}
                          className="form-control floating datetimepicker"
                          type="date"
                          dateFormat="dd-MM-yyyy"
                        />
                      )}
                    />
                    <span className="text-danger">
                      {errors?.end_date?.message}
                    </span>
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Number of days <span className="text-danger">*</span>
                  </label>
                  <input className="form-control" readOnly type="text" value={noOfDays} />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Remaining Leaves <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    readOnly
                    defaultValue={12}
                    type="text"
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Reason <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="reason"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <textarea
                        className="form-control"
                        rows={4}
                        defaultValue={""}
                        onChange={onChange}
                      >{value}</textarea>
                    )}
                  />
                  <span className="text-danger">
                    {errors?.reason?.message}
                  </span>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    disabled={isLoading}
                    type="submit"
                  >
                    {!isLoading ? 'Submit' : <ClipLoader color="#ffffff" />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
