// AUTH
export const LOGIN = '/auth/login';
export const DETAILS = '/auth/details';
export const CHANGE_PASSWORD = '/auth/change-password';

// SETTINGS
export const BANKS = '/banks';
export const BRANCHES = '/branches';
export const DEPARTMENTS = '/departments';
export const DESIGNATIONS= '/designations';
export const DOCUMENT_TYPES = '/document-types';
export const HOLIDAYS = '/holidays';
export const LEAVE_TYPES = '/leave-types';
export const TERMINATION_TYPES = '/termination-types';

// EMPLOYEES
export const EMPLOYEE = '/employees';
export const GET_EMPLOYEES = '/employees/list';
export const SEARCH_EMPLOYEES = '/employees/search';
export const BIOTIME_SEARCH = '/employess/biotime/search';
export const UPDATE_BIOTIME_ID = '/employees/update-emp-id';
export const UPLOAD_EMP_DOCUMENTS = '/employees/upload-documents';

// HR/PERFORMANCE
export const LEAVES = '/hr/leaves';
export const PROMOTIONS = '/hr/promotions';
export const TRANSFERS = '/hr/transfers';
export const TERMINATIONS = '/hr/terminations';
export const RESIGNATIONS = '/hr/resignations';

// ATTENDANCE
export const ATT_FIRST_IN_LAST_OUT = '/attendance/first-in-last-out';
export const ATT_TIME_CARD = '/attendance/time-card';
export const ATT_DAILY = '/attendance/daily-attendance';
export const ATT_SUMMARY = '/attendance/summary';
export const ATT_DETAILS = '/attendance/info';
export const ATT_EMPLOYEE = '/attendance/employee';
export const ATT_ADD_EXCEPTION= '/attendance/add-exception';

// LOANS
export const LOANS = '/loans';

//NOTIFICATIONS

//PAYROLL

//PERFORMANCE


