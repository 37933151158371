import apiSlice from "./api/api";
import alertify from "alertifyjs";
import { CHANGE_PASSWORD, DETAILS, LOGIN } from "./ENDPOINTS";
import { login } from "../redux/slices/user.slice";

const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Login user
    login: builder.mutation({
      query: (userData) => ({
        url: LOGIN,
        body: userData,
        method: "POST",
      }),
      onQueryStarted: async (credentials, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.success) {
            dispatch(
              login({
                token: data.data.token,
                user: data.data.user,
                isAuthenticated: true,
              })
            );
            localStorage.setItem("colorschema", "orange");
            localStorage.setItem("layout", "vertical");
            localStorage.setItem("layoutwidth", "fixed");
            localStorage.setItem("layoutpos", "fluid");
            localStorage.setItem("topbartheme", "light");
            localStorage.setItem("layoutSized", "lg");
            localStorage.setItem("layoutStyling", "default");
            localStorage.setItem("layoutSidebarStyle", "dark");
          } else {
            alertify.error(data.message)
          }
        } catch (error) {
          return;
        }
      },
      transformResponse: (response) => {
        return response;
      },
      invalidatesTags: ["User"],
    }),

    // Change password
    changePassword: builder.mutation({
      query: (data) => ({
        url: CHANGE_PASSWORD,
        body: data,
        method: "POST",
      }),
    }),

    // reset password
    // resetPassword: builder.mutation({
    //   query: (data) => ({
    //     url: RESET_PASSWORD,
    //     body: data,
    //     method: "POST",
    //   }),
    // }),

    // get user details
    getUserDetails: builder.query({
      query: () => ({
        url: DETAILS,
        method: "GET",
      }),
      providesTags: ["User"],
    }),
    // get user notification
    // getNotifications: builder.query({
    //   query: () => ({
    //     url: GET_NOTIFICATIONS,
    //     method: "GET",
    //   }),
    //   providesTags: ["Notifications"],
    // }),
    // get user notification
    // updateReadStatus: builder.mutation({
    //   query: (id) => ({
    //     url: `${UPDATE_READ_STATUS}/${id}`,
    //     method: "GET",
    //   }),
    //   invalidatesTags: ["Notifications"],
    // }),
    // readAll: builder.mutation({
    //   query: (id) => ({
    //     url: `${UPDATE_ALL_STATUS}`,
    //     method: "GET",
    //   }),
    //   invalidatesTags: ["Notifications"],
    // }),
  }),
});

export const {
  useGetUserDetailsQuery,
  useLoginMutation,
  useChangePasswordMutation,
  // useResetPasswordMutation,
  // useGetNotificationsQuery,
  // useUpdateReadStatusMutation,
  // useReadAllMutation
} = authApiSlice;
