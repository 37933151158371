import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Avatar_02 } from "../../../Routes/ImagePath";
import { Table } from "antd";
import Breadcrumbs from "../../../components/Breadcrumbs";
import LoanFormModal from "../../../components/modelpopup/LoanFormModal";
import { useLazyGetLoansQuery } from "../../../services/employee.service";
import { useDispatch } from "react-redux";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import { LOANS } from "../../../services/ENDPOINTS";
import { FilterBox } from "../../../components/FilterBox";
import { render } from "@testing-library/react";
import { formatNumber } from "../../../utils";

const Loans = () => {
  const [selected, setSelected] = useState(null);
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({});
  const [results, setResult] = useState([]);
  const dispatch = useDispatch();

  const [getResults, {data, isFetching, error}] = useLazyGetLoansQuery();

  useEffect(() => {
    getResults({page: 1, search: ''});
  }, []);

  useEffect(() => {
    if (data) {
      setResult(data?.data?.result || []);
      setPagination(data?.data.meta);
    }
  }, [data]);

  const doDelete = (e, item) => {
    // e.currentTarget.closest('tr').remove()
    dispatch(
      toggleDelete({
        open: true,
        title: 'Loan',
        url: `${LOANS}/${item.id}`,
        ele: e.currentTarget,
        parent: 'tr'
      })
    );
  }

  const statsData = [
    {
      title: "Total Amount",
      value: '1,122,000',
      // month: "this month",
    },
    {
      title: "Total Paid",
      value: '750,000',
      // month: "this month",
    },
    {
      title: "Pending Request",
      value: 23,
    },
    {
      title: "Rejected",
      value: 5,
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id.length - b.id.length,
      render: (t, r, i) => i + 1
    },
    {
      title: "Employee Name",
      dataIndex: "name",
      render: (text, record) => (
        <h2 className="table-avatar d-flex">
          <Link to={`/employee/${record.employee.id}`} className="avatar">
            <img
              alt=""
              src={
                record.emp_code
                  ? `http://129.18.184.239:8091/auth_files/photo/${record.emp_code}.jpg`
                  : Avatar_02
              }
            />
          </Link>
          <Link to={`/employee/${record.employee.id}`} className="profile-split d-flex flex-column">
            {`${record.employee.first_name} ${record.employee.last_name}`}
            <span>{record.employee.designation.name}</span>
          </Link>
        </h2>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.otdate.length - b.otdate.length,
      render: (text) => formatNumber(text)
    },

    {
      title: "Tenure",
      dataIndex: "tenure",
      sorter: (a, b) => a.othours.length - b.othours.length,
      render: (text) => `${text} months`
    },

    {
      title: "Repayment",
      dataIndex: "monthly_repayment",
      sorter: (a, b) => a.monthly_repayment.length - b.monthly_repayment.length,
      render: (text) => formatNumber(text)
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      sorter: (a, b) => a.start_date.length - b.start_date.length,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      sorter: (a, b) => a.end_date.length - b.end_date.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <div className="dropdown action-label text-center">
          <Link
            className="btn btn-white btn-sm btn-rounded "
            to="#"
            aria-expanded="false"
          >
            <i
              className={
                text === "New"
                  ? "far fa-dot-circle text-purple"
                  : text === "Pending"
                  ? "far fa-dot-circle text-info"
                  : text === "Approved"
                  ? "far fa-dot-circle text-success"
                  : "far fa-dot-circle text-danger"
              }
            />{" "}
            {text}
          </Link>
          <div className="dropdown-menu">
            <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-warning" /> Pending</a>
            <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success" /> Approve</a>
            <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger" /> Deny</a>
          </div>
        </div>
      ),
    },

    {
      title: "Approved By",
      dataIndex: "approvedby",
      render: (text, record) => (
        <span className="table-avatar">
          <Link to="/profile" className="avatar">
            <img alt="" src={record.apimage} />
          </Link>
          <Link to="/profile">{text}</Link>
        </span>
      ),
      sorter: (a, b) => a.approvedby.length - b.approvedby.length,
    },
    {
      title: "Action",
      render: (t, r) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#loan_modal"
              onClick={() => setSelected(r)}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={(e) => doDelete(e, r)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Manage Loans"
            title="Dashboard"
            subtitle="Loans"
            modal="#loans_modal"
            name="Add New Loan"
          />

          {/* /Page Header */}
          <div className="row">
            {statsData.map((data, index) => (
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3" key={index}>
                <div className="stats-info">
                  <h6>{data.title}</h6>
                  <h4>
                    {data.value} <span>{data.month}</span>
                  </h4>
                </div>
              </div>
            ))}
          </div>
          {/* /Overtime Statistics */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <FilterBox 
                  getResults={(val) => {
                    setSearch(val);
                    getResults({page: 1, search: val});
                  }} 
                />
                <Table
                  className="table-striped"
                  columns={columns}
                  loading={isFetching}
                  dataSource={results}
                  rowKey={(record) => record.id}
                  pagination={{ 
                    defaultPageSize: 100, 
                    total: pagination?.total,
                    onChange: (page, pageSize) => {
                      getResults({page, search});
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      <LoanFormModal item={selected} />
    </>
  );
};

export default Loans;
