import { useGetBranchesQuery, useGetDepartmentsQuery, useGetDesignationsQuery } from "../../../services/settings.service";
import { PersonalInfoForm } from "../../Forms/PersonalInfoForm";

const PersonalInfoModal = ({data, user}) => {
  // const {alert} = useSelector((state) => state.appData);
  const { data: branchData } = useGetBranchesQuery();
  const { data: deptData } = useGetDepartmentsQuery();
  const { data: designationData } = useGetDesignationsQuery();

  return (
    <div
        id="personal_info_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Personal Info</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">

              <PersonalInfoForm 
                empId={data?.id}
                profile={data} 
                branches={branchData?.data?.map(branch => ({value: branch.id, label: branch.name}))} 
                designations={designationData?.data?.map(row => ({value:row.id, label: row.name}))} 
                departments={deptData?.data?.map(row => ({value: row.id, label: row.name}))}
                onNext={(e) => console.log(e)}
                user={user}
              />
            </div>
          </div>
        </div>
      </div>
  )
}
export default PersonalInfoModal;
