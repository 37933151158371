import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

const EmployeeListFilter = ({branches, departments, designations, filter, setFilter, user, submit}) => {

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#008a42" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#008a42",
      },
    }),
  };
  const [focused1, setFocused1] = useState(false);

  const handleLabelClick2 = () => {
    setFocused1(true);
  };


  const handleInputBlur2 = () => {
    if (filter.employee_name === "") {
      setFocused1(false);
    }
  };

  const handleInputChange2 = (e) => {
    const value = e.target.value;
    setFilter({...filter, employee_name: value});
    if (value !== "" && !focused1) {
      setFocused1(true);
    }
  };

  return (
    <div>
      <div className="row filter-row">
      {['HR Admin', 'Admin', 'HR Assistant'].includes(user?.role?.name) && (
        <div className="col">
          <div className="input-block form-focus select-focus">
            <Select
              placeholder="Select Branch"
              styles={customStyles}
              value={branches?.find(c => c.value === filter.branch_id)}
              options={
                (branches?.map((row) => ({
                    label: row.name,
                    value: row.id,
                  }))) ||
                []
              }
              onChange={({value}) =>
                setFilter({ ...filter, branch_id: value })
              }
            />
            <label className="focus-label">Branch</label>
          </div>
        </div>
      )}
        <div className="col">
          <div
            className={
              focused1 || filter?.employee_name !== ""
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={filter?.employee_name}
              onFocus={handleLabelClick2}
              onBlur={handleInputBlur2}
              onChange={handleInputChange2}
              onKeyUp={(e) => e.key === 'Enter' ? submit() : '' }
            />
            <label className="focus-label" onClick={handleLabelClick2}>
              Employee Name
            </label>
          </div>
        </div>
        <div className="col">
          <div className="input-block form-focus select-focus">
            <Select
              placeholder="Select Designation"
              styles={customStyles}
              value={designations.find(c => c.value === filter.designation_id)}
              optionFilterProp="children"
              // filterOption={(input, option) =>
              //   option.label.toLowerCase().includes(input.toLowerCase())
              // }
              options={
                (designations?.map((designation) => ({
                    label: designation.name,
                    value: designation.id,
                  }))) ||
                []
              }
              onChange={({value}) =>
                setFilter({ ...filter, designation_id: value })
              }
            />
            <label className="focus-label">Designation</label>
          </div>
        </div>
        <div className="col">
          <div className="input-block form-focus select-focus">
            <Select
              placeholder="Select Department"
              styles={customStyles}
              value={departments?.find(c => c.value === filter.department_id)}
              optionFilterProp="children"
              options={
                (departments?.map((row) => ({
                    label: row.name,
                    value: row.id,
                  }))) ||
                []
              }
              onChange={({value}) =>
                setFilter({ ...filter, department_id: value })
              }
            />
            <label className="focus-label">Department</label>
          </div>
        </div>
        <div className="col">
          <Link to="#" onClick={submit} className="btn btn-success btn-block w-100">
            Search
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EmployeeListFilter;
