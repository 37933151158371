import apiSlice from "./api/api";
import { EMPLOYEE, GET_EMPLOYEES, LOANS, SEARCH_EMPLOYEES } from "./ENDPOINTS";

const employeeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // get employee details
    getEmployee: builder.query({
        query: (id) => ({
        url: `${EMPLOYEE}/${id}`,
        method: "GET",
        }),
        providesTags: ["Employee"],
    }),
    // search employees
    searchEmployees: builder.query({
      query: ({q, branch_id}) => ({
      url: `${SEARCH_EMPLOYEES}?q=${q}&branch_id=${branch_id}`,
      method: "GET",
      }),
      providesTags: ["Employee"],
    }),
    // fetch employees
    getEmployees: builder.mutation({
      query: ({filter, page, limit}) => ({
        url: `${GET_EMPLOYEES}?page=${page}&limit=${limit}`,
        method: "POST",
        body: filter
      }),
      invalidatesTags: ["Employees"],
    }),
    // save employee info
    saveEmployees: builder.mutation({
      query: (data) => ({
        url: EMPLOYEE,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Employees"],
    }),
    updateEmployee: builder.mutation({
      query: ({data, id}) => ({
        url: `${EMPLOYEE}/${id}/update`,
        method: "PUT",
        body: data
      }),
      invalidatesTags: ["Employee"],
    }),
    // save education info
    saveEducation: builder.mutation({
      query: ({data, id}) => ({
        url: `${EMPLOYEE}/${id}/education`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Employee"],
    }),
    // save work history info
    saveWorkHistory: builder.mutation({
      query: ({data, id}) => ({
        url: `${EMPLOYEE}/${id}/work-history`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Employee"],
    }),
    // save next of kin
    saveNextOfKin: builder.mutation({
      query: ({data, id}) => ({
        url: `${EMPLOYEE}/${id}/next-of-kin`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Employee"],
    }),
    // save parents
    saveParents: builder.mutation({
      query: ({data, id}) => ({
        url: `${EMPLOYEE}/${id}/family-info`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Employee"],
    }),
    // save dependants info
    saveDependants: builder.mutation({
      query: ({data, id}) => ({
        url: `${EMPLOYEE}/${id}/dependants`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Employee"],
    }),
    // save place of worship
    savePlaceOfWorship: builder.mutation({
      query: ({data, id}) => ({
        url: `${EMPLOYEE}/${id}/place-of-worship`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Employee"],
    }),
    // get loans
    getLoans: builder.query({
      query: ({page, search}) => ({
        url: `${LOANS}/list?page=${page}&q=${search}`,
        method: "GET",
      }),
      providesTags: ["Loans"],
    }),
    // save leaves
    saveLoans: builder.mutation({
      query: (data) => ({
        url: LOANS,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Loans"],
    }),
  })
});

export const {
    useLazyGetLoansQuery,
    useSaveLoansMutation,
    useSaveDependantsMutation,
    useSaveEducationMutation,
    useSaveNextOfKinMutation,
    useSaveParentsMutation,
    useSavePlaceOfWorshipMutation,
    useSaveWorkHistoryMutation,
    useGetEmployeesMutation,
    useLazySearchEmployeesQuery,
    useSaveEmployeesMutation,
    useUpdateEmployeeMutation,
    useGetEmployeeQuery
  } = employeeApiSlice;