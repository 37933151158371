import React, {useEffect, useState} from "react";
import { Empty } from "antd";
import dayjs from "dayjs";
import EducationModal from "../../../../components/modelpopup/Profile/EducationModal";

export const EducationInformation = ({details}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(details?.educations);
    }, [details]);

    const refreshData = data => {
        setData(data);
    }

    return (
        <div className="card profile-box flex-fill">
            <div className="card-body">
                <h3 className="card-title">Education Information
                  <a href="#" className="edit-icon" data-bs-toggle="modal" data-bs-target="#education_info">
                    <i className="fa fa-pencil" />
                  </a>
                </h3>
                <div className="experience-box">
                    {data && data.length > 0 ?
                        <ul className="experience-list">
                            {data.map((row, i) =>
                                <li key={`education-list-${i}`}>
                                    <div className="experience-user">
                                        <div className="before-circle"/>
                                    </div>
                                    <div className="experience-content">
                                        <div className="timeline-content">
                                            <a href="javascript:;" className="name">{row.institution}</a>
                                            <div>{row.certificate}</div>
                                            <span className="time">
                                              {row.start_date ? dayjs(row.start_date).format('YYYY') : 'N/A'}
                                              &nbsp;-&nbsp;
                                              {row.end_date ? dayjs(row.end_date).format('YYYY') : 'N/A'}
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            )}
                        </ul>
                        :
                        <div className="text-center">
                            <Empty />
                            <button className="btn btn-primary"  data-bs-toggle="modal"  data-bs-target="#education_info">
                                <i className="la la-plus mr-1"/>Add
                            </button>
                        </div>
                    }
                </div>
            </div>
            {/* Education Modal */}
            <EducationModal data={details} refresh={refreshData} />
            {/* /Education Modal */}
        </div>
    )
}
