import React, {useEffect, useState} from "react";
import { Empty } from "antd";
import ParentsModal from "../../../../components/modelpopup/Profile/ParentsModal";

export const FamilyInformation = ({details}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(details?.family);
    }, [details]);

    const refreshData = data => {
        setData(data);
    }

    return (
        <div className="card profile-box flex-fill">
            <div className="card-body">
                <h3 className="card-title">Family Information
                  <a href="javascript:;" className="edit-icon"  data-bs-toggle="modal" data-bs-target="#family_info_modal">
                    <i className="fa fa-pencil" />
                  </a>
                </h3>
                <div className="table-responsive">
                    {data && data.length > 0 ?
                        <table className="table table-nowrap">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Relationship</th>
                                <th>Place of Worship</th>
                                <th>Address</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((row, i) =>
                                <tr key={`family-list-${i}`}>
                                    <td>{row.name}</td>
                                    <td>{row.relationship}</td>
                                    <td>{row.place_of_worship}</td>
                                    <td>{row.address}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        :
                        <div className="text-center">
                            <Empty/>
                            <button className="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#family_info_modal">
                                <i className="la la-plus mr-1"/>Add
                            </button>
                        </div>
                    }
                </div>
            </div>
            {/* Family Info Modal */}
            <ParentsModal data={details} refresh={refreshData} />
        </div>
    )
}
