import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import alertify from "alertifyjs";
import { toggleDelete } from "../../redux/slices/ui.slice";
import { useDeleteItemMutation } from "../../services/settings.service";


// @ts-ignore
export default function DeleteModal({ data }) {
    const dispatch = useDispatch();
    const [doDelete, {isLoading}] = useDeleteItemMutation();

    const handleClose = () => {
        dispatch(
          toggleDelete({
            open: false,
            url: null,
            title: null
          })        
        );
    }

    const deleteItem = async () => {
        // console.log(data);
        await doDelete(data.url).then(res => {
          if (res.data.success) {
            data.ele.closest(data.parent || 'tr').remove();
            alertify.success(res.data.message);
          } else {
            alertify.error(res.data.message)
          }
          handleClose()
        }).catch(err => {
          alertify.error('Unable to process reques');
          handleClose();
        })
    }

    return (
        <Modal
            show={true}
            className="custom-modal"
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Body>
                <div className="form-header">
                    <h3>Delete {data?.title}</h3>
                    <p>Are you sure want to delete?</p>
                </div>
                <div className="modal-btn delete-action">
                    <div className="row">

                        <div className="col-6">
                            <a href="javascript:;" onClick={handleClose} className="btn btn-primary cancel-btn">Cancel</a>
                        </div>
                        <div className="col-6">
                            <a
                                href="javascript:;"
                                onClick={deleteItem}
                                className="btn btn-primary continue-btn"
                            >
                                {isLoading ? 'Deleting...' : 'Delete'}
                            </a>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
