import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { Avatar_02 } from "../../../Routes/ImagePath/index";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ResignationModal from "../../../components/modelpopup/ResignationModal";
import { useLazyGetResignationQuery } from "../../../services/hr.service";
import dayjs from "dayjs";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import { RESIGNATIONS } from "../../../services/ENDPOINTS";
import { useDispatch } from "react-redux";
import { FilterBox } from "../../../components/FilterBox";

const Resignation = () => {
  const [selected, setSelected] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({});
  const [results, setResult] = useState([]);
  const dispatch = useDispatch();

  const [getResults, {data, isFetching, error}] = useLazyGetResignationQuery();

  useEffect(() => {
    getResults({page: currentPage, search: ''});
  }, []);

  useEffect(() => {
    if (data) {
      setResult(data?.data?.result || []);
      setPagination(data?.data.meta);
    }
  }, [data]);

  const columns = [
    {
      title: '',
      render: (text, record) =>
        // hasPermission('write', 'resignations', user?.role?.permissions) && (
          <div className="dropdown dropdown-action text-right">
            <a
              href="javascript:;"
              className="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="material-icons">more_vert</i>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => setSelected(record)}
                data-toggle="modal"
                data-target="#add_resignation"
              >
                <i className="fa fa-pencil m-r-5" /> Edit
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => doDelete(e, record)}
                
              >
                <i className="fa fa-trash m-r-5" /> Delete
              </a>
            </div>
          </div>
        // ),
    },
    {
      title: 'Resigning Employee',
      dataIndex: 'name',
      render: (text, record) => (
        <h2 className="table-avatar d-flex">
          <Link to={`/employee/${record.employee.id}`} className="avatar">
            <img
              alt=""
              src={
                record.emp_code
                  ? `http://129.18.184.239:8091/auth_files/photo/${record.emp_code}.jpg`
                  : Avatar_02
              }
            />
          </Link>
          <Link to={`/employee/${record.employee.id}`} className="profile-split d-flex flex-column">
            {`${record.employee.first_name} ${record.employee.last_name}`}
            <span>{record.employee.designation.name}</span>
          </Link>
        </h2>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Department',
      dataIndex: 'department',
      sorter: (a, b) => a.department.length - b.department.length,
      render: (text, record) => record.employee.department.name,
    },

    {
      title: 'Reason',
      dataIndex: 'reason',
      sorter: (a, b) => a.reason.length - b.reason.length,
    },

    {
      title: 'Notice Date',
      dataIndex: 'notice_date',
      sorter: (a, b) => a.notice_date.length - b.notice_date.length,
      render: (text, record) =>
        dayjs(record.notice_date).format('DD MMM YYYY'),
    },
    {
      title: 'Resignation Date',
      dataIndex: 'resignation_date',
      sorter: (a, b) => a.resignation_date.length - b.resignation_date.length,
      render: (text, record) =>
        dayjs(record.resignation_date).format('DD MMM YYYY'),
    },
    {
      title: 'Action',
      render: (text, record) =>
        // hasPermission('write', 'resignations', user?.role?.permissions) && (
          <div className="dropdown dropdown-action text-right">
            <a
              href="javascript:;"
              className="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="material-icons">more_vert</i>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <Link
                className="dropdown-item"
                to="#"
                onClick={(e) => setSelected(record)}
                data-bs-toggle="modal"
                data-bs-target="#add_resignation"
              >
                <i className="fa fa-pencil m-r-5" /> Edit
              </Link>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => doDelete(e, record)}
                
              >
                <i className="fa fa-trash m-r-5" /> Delete
              </a>
            </div>
          </div>
        // ),
    },
  ];

  const doDelete = (e, item) => {
    // e.currentTarget.closest('tr').remove()
    dispatch(
      toggleDelete({
        open: true,
        title: 'Resignation',
        url: `${RESIGNATIONS}/${item.id}`,
        ele: e.currentTarget,
        parent: 'tr'
      })
    );
  }

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Manage Resignation"
            title="Dashboard"
            subtitle="Resignation"
            modal="#add_resignation"
            name="Add Resignation"
          />
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <FilterBox 
                  getResults={(val) => {
                    setSearch(val);
                    getResults({page: 1, search: val});
                  }} 
                />
                {/* Promotion Table */}
                <Table
                  className="table-striped"
                  columns={columns}
                  loading={isFetching}
                  dataSource={results}
                  rowKey={(record) => record.id}
                  pagination={{ 
                    defaultPageSize: 100, 
                    total: pagination?.total,
                    onChange: (page, pageSize) => {
                      getResults({page, search});
                    },
                  }}
                />
                {/* /Promotion Table */}
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      <ResignationModal item={selected} />
    </>
  );
};

export default Resignation;
