import React, { useEffect, useState } from "react";
import { Select } from "antd";
import DatePicker from "react-datepicker";
import { useSaveTerminationMutation } from "../../services/hr.service";
import { useForm, Controller } from "react-hook-form";
import alertify from "alertifyjs";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { useGetTerminationTypesQuery } from "../../services/settings.service";
import { TerminationSchema } from "../../utils/validationSchema";
import dayjs from "dayjs";
import { customStyles } from "../../utils";
import { ClipLoader } from "react-spinners";
import { useLazySearchEmployeesQuery } from "../../services/employee.service";
import { useSelector } from "react-redux";
const { Option } = Select;

const TerminationModal = ({item}) => {
  const {user} = useSelector((state) => state.user);

  const branch_id = (!['HR Admin', 'Admin', 'HR Assistant'].includes(user?.role?.name)) ? user?.branch?.id : ''
  const [employees, setEmployees] = useState([]);
  const [searchEmployees, {data: searchData, error: searchError}] = useLazySearchEmployeesQuery();
  const [saveResignation, {data, isLoading, isSuccess, error}] = useSaveTerminationMutation();
  const {data: terminationTypeData} = useGetTerminationTypesQuery();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(TerminationSchema),
  });

  useEffect(() => {
    if (searchData) {
      setEmployees(searchData?.data)
    }
  }, [searchData, searchError])

  useEffect(() => {
    if (isSuccess && data?.success){
      alertify.success(data?.message);
      document.getElementById('close-modal').click();

      setValue('employee_id', "");
      setValue('termination_type_id', "");
      setValue('notice_date', "");
      setValue('termination_date', "");
      setValue('reason', "");
      setValue('id', "");
    }

    if (data && !data.success)
      alertify.error(data.errors);

    if (error) 
      alertify.error(error?.message);

  }, [data, error, isSuccess]);

  useEffect(() => {
    if (item) {
      setEmployees([...employees, {
        id: item.employee.id,
        name: item.employee.first_name + ' ' + item.employee.last_name,
        designation: item.employee.designation,
        department: item.department
      }]);
      setValue('employee_id', item.employee.id);
      setValue('termination_type_id', item.termination_type.id);
      setValue('notice_date', dayjs(item.notice_date).toDate());
      setValue('termination_date', dayjs(item.termination_date).toDate());
      setValue('reason', item.reason);
      setValue('id', item.id);
    }
  }, [item]);

  const findEmployees = (value) => {
    if (value) {
      searchEmployees({q:value, branch_id});
    } else {
      setEmployees([]);
    }
  }

  const save = payload => {
    payload.notice_date = dayjs(payload.notice_date).format('YYYY-MM-DD');
    payload.termination_date = dayjs(payload.termination_date).format('YYYY-MM-DD');
    saveResignation(payload);
  }

  return (
    <>
      {/* Add Termination Modal */}
      <div
        id="termination_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{item ? 'Edit' : 'Add'} Termination</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-modal"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(save)}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Terminated Employee <span className="text-danger">*</span>
                  </label>
                    <Controller
                      name="employee_id"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Select
                          showSearch
                          size="large"
                          value={value}
                          style={{width: '100%'}}
                          defaultActiveFirstOption={false}
                          filterOption={false}
                          onSearch={findEmployees}
                          onChange={onChange}
                          notFoundContent={null}
                        >
                          {employees.map(d => <Option key={d.id} value={d.id}>{d.name}</Option>)}
                        </Select>
                      )}
                    />
                    <span className="text-danger">
                      {errors?.employee_id?.message}
                  </span>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Termination Type <span className="text-danger">*</span>
                  </label>
                  <div className="add-group-btn">
                    <Controller
                      name="termination_type_id"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Select
                          size="large"
                          value={value}
                          placeholder="Select "
                          styles={customStyles}
                          style={{width: '100%'}}
                          onChange={onChange}
                        >
                          {terminationTypeData?.data.map(d => <Option key={d.id} value={d.id}>{d.name}</Option>)}
                        </Select>
                      )}
                    />
                    <span className="text-danger">
                      {errors?.termination_type_id?.message}
                  </span>
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Termination Date <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <div className="cal-icon">
                    <Controller
                      name="termination_date"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <DatePicker
                          selected={value || null}
                          onChange={onChange}
                          className="form-control floating datetimepicker"
                          type="date"
                          dateFormat="dd-MM-yyyy"
                        />
                      )}
                    />
                    <span className="text-danger">
                        {errors?.termination_daten?.message}
                    </span>
                  </div>
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Reason <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="reason"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <textarea
                        className="form-control"
                        rows={4}
                        defaultValue={""}
                        onChange={onChange}
                      >{value}</textarea>
                    )}
                  />
                  <span className="text-danger">
                    {errors?.reason?.message}
                  </span>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Notice Date <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                  <div className="cal-icon">
                    <Controller
                      name="notice_date"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <DatePicker
                          selected={value || null}
                          onChange={onChange}
                          className="form-control floating datetimepicker"
                          type="date"
                          dateFormat="dd-MM-yyyy"
                        />
                      )}
                    />
                  </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    disabled={isLoading}
                    type="submit"
                  >
                    {!isLoading ? 'Submit' : <ClipLoader color="#ffffff" />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Termination Modal */}
      
    </>
  );
};

export default TerminationModal;
