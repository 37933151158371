import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { useLazySearchEmployeesQuery } from "../../services/employee.service";
import { useForm, Controller } from "react-hook-form";
import alertify from "alertifyjs";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { Select } from "antd";
import { useSaveResignationMutation } from "../../services/hr.service";
import dayjs from "dayjs";
import { ClipLoader } from "react-spinners";
import { ResignationSchema } from "../../utils/validationSchema";
const { Option } = Select;

const ResignationModal = ({item}) => {
  const {user} = useSelector((state) => state.user);

  const branch_id = (!['HR Admin', 'Admin', 'HR Assistant'].includes(user?.role?.name)) ? user?.branch?.id : ''
  const [employees, setEmployees] = useState([]);
  const [searchEmployees, {data: searchData, error: searchError}] = useLazySearchEmployeesQuery();
  const [saveResignation, {data, isLoading, isSuccess, error}] = useSaveResignationMutation();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ResignationSchema),
  });

  useEffect(() => {
    if (searchData) {
      setEmployees(searchData?.data)
    }
  }, [searchData, searchError])

  useEffect(() => {
    if (isSuccess && data?.success){
      alertify.success(data?.message);
      document.getElementById('close-resignation').click();
      setValue('employee_id', "");
      setValue('notice_date', "");
      setValue('resignation_date', "");
      setValue('reason', "");
      setValue('id', "");
    }

    if (data && !data.success)
      alertify.error(data.errors);

    if (error) 
      alertify.error(error?.message);

  }, [data, error, isSuccess]);

  useEffect(() => {
    if (item) {
      setEmployees([...employees, {
        id: item.employee.id,
        name: item.employee.first_name + ' ' + item.employee.last_name,
        designation: item.employee.designation,
        department: item.department
      }]);
      setValue('employee_id', item.employee.id);
      setValue('notice_date', dayjs(item.notice_date).toDate());
      setValue('resignation_date', dayjs(item.resignation_date).toDate());
      setValue('reason', item.reason);
      setValue('id', item.id);
    }
  }, [item]);

  const findEmployees = (value) => {
    if (value) {
      searchEmployees({q:value, branch_id});
    } else {
      setEmployees([]);
    }
  }

  const save = payload => {
    payload.notice_date = dayjs(payload.notice_date).format('YYYY-MM-DD');
    payload.resignation_date = dayjs(payload.resignation_date).format('YYYY-MM-DD');
    saveResignation(payload);
  }

  return (
    <>
      {/* Add Resignation Modal */}
      <div
        id="add_resignation"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{item ? 'Edit' : 'Add' } Resignation</h5>
              <button
                type="button"
                id="close-resignation"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(save)}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Resigning Employee <span className="text-danger">*</span>
                  </label>
                  <Controller
                      name="employee_id"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Select
                          showSearch
                          size="large"
                          value={value}
                          style={{width: '100%'}}
                          defaultActiveFirstOption={false}
                          filterOption={false}
                          onSearch={findEmployees}
                          onChange={(value) => onChange(value)}
                          notFoundContent={null}
                        >
                            {employees.map(d => <Option key={d.id} value={d.id}>{d.name}</Option>)}
                        </Select>
                      )}
                    />
                    <span className="text-danger">
                        {errors?.employee_id?.message}
                    </span>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Notice Date <span className="text-danger">*</span>
                  </label>

                  <div className="cal-icon">
                    <Controller
                      name="notice_date"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <DatePicker
                          selected={value || null}
                          onChange={onChange}
                          className="form-control floating datetimepicker"
                          type="date"
                          dateFormat="dd-MM-yyyy"
                        />
                      )}
                    />
                    <span className="text-danger">
                        {errors?.notice_date?.message}
                    </span>
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Resignation Date <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <Controller
                      name="resignation_date"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <DatePicker
                          selected={value || null}
                          onChange={onChange}
                          className="form-control floating datetimepicker"
                          type="date"
                          dateFormat="dd-MM-yyyy"
                        />
                      )}
                    />
                    <span className="text-danger">
                        {errors?.resignation_dateg?.message}
                    </span>
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Reason <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="reason"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <textarea
                        className="form-control"
                        rows={4}
                        defaultValue={""}
                        onChange={onChange}
                      >{value}</textarea>
                    )}
                  />
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    disabled={isLoading}
                    aria-label="Close"
                    type="submit"
                  >
                    {!isLoading ? 'Submit' : <ClipLoader color="#ffffff" />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Resignation Modal */}
      
    </>
  );
};

export default ResignationModal;
