/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Avatar_02, Avatar_16 } from "../../../Routes/ImagePath";
import { Link, useParams } from "react-router-dom";
import ProfileTab from "./ProfileTab";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useGetEmployeeQuery } from "../../../services/employee.service";
import { Skeleton } from "antd";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const Profile = () => {
  const {id} = useParams();
  const {user} = useSelector(state => state.user);
  const {data, isLoading} = useGetEmployeeQuery(id, {skip: !id});
  const [userData, setUserData] = useState(null);

  // const userData = {
  //   id: 1,
  //   name: "John Doe",
  //   role: "UI/UX Design Team",
  //   jobTitle: "Web Designer",
  //   employeeId: "FT-0001",
  //   dateOfJoin: "1st Jan 2023",
  //   phone: "9876543210",
  //   email: "johndoe@example.com",
  //   birthday: "24th July",
  //   address: "1861 Bayonne Ave, Manchester Township, NJ, 08759",
  //   gender: "Male",
  //   supervisor: {
  //     name: "Jeffery Lalor",
  //     avatar: "assets/img/profiles/avatar-16.jpg",
  //   },
  // };

  useEffect(() => {
    if (data && data.success)
        setUserData(data.data);
  }, [data]);


  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Profile"
            title="Employees"
            subtitle="Profile"
            modal="#add_indicator"
            name="Add New"
          />
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <Link to="#">
                          <img src={Avatar_02} alt="User Image" />
                        </Link>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                        {!userData ?
                            <Skeleton paragraph={{rows: 4}}/>
                            :
                            <div className="profile-info-left">
                              <h3 className="user-name m-t-0 mb-0">{`${userData?.first_name} ${userData?.last_name}`}</h3>
                              <div className="staff-id">Employee ID : {userData?.emp_code}</div>
                              <h6 className="text-muted">{userData?.department?.name }, {userData?.designation?.name }</h6>
                              {userData?.branch && <div className="small doj text-muted">Branch : {userData?.branch.name}</div>}
                              {userData?.join_date && <div className="small doj text-muted">Date of Join : {dayjs(userData?.join_date).format('do MMM yyyy')}</div>}
                              <div className="staff-msg">
                                <a className="btn btn-custom" href="#">Send Message</a>
                              </div>
                            </div>
                          }
                        </div>
                        <div className="col-md-7">
                          {!userData ?
                              <Skeleton paragraph={{rows: 4}}/>
                              :
                              <ul className="personal-info">
                                <li>
                                  <div className="title">Phone:</div>
                                  <div className="text"><a href="">{userData.phone_number}</a></div>
                                </li>
                                {userData.user &&
                                <li>
                                  <div className="title">Email:</div>
                                  <div className="text"><a href="">{userData?.user?.email}</a></div>
                                </li>}
                                <li>
                                  <div className="title">Birthday:</div>
                                  <div className="text">{dayjs(userData.date_of_birth).format('do MMMM')}</div>
                                </li>
                                <li>
                                  <div className="title">Address:</div>
                                  <div className="text">{userData.address}</div>
                                </li>
                                <li>
                                  <div className="title">Gender:</div>
                                  <div className="text">{userData.gender}</div>
                                </li>
                                {/*<li>
                                  <div className="title">Reports to:</div>
                                  <div className="text">
                                    <div className="avatar-box">
                                      <div className="avatar avatar-xs">
                                        <img src={Avatar_16} alt=""/>
                                      </div>
                                    </div>
                                    <a href="/maroon/app/profile/employee-profile">
                                      Jeffery Lalor
                                    </a>
                                  </div>
                                </li>*/}
                              </ul>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="pro-edit">
                      <Link
                        data-bs-target="#profile_info"
                        data-bs-toggle="modal"
                        className="edit-icon"
                        to="#"
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card tab-box">
            <div className="row user-tabs">
              <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                <ul className="nav nav-tabs nav-tabs-bottom">
                  <li className="nav-item">
                    <Link
                      to="#emp_profile"
                      data-bs-toggle="tab"
                      className="nav-link active"
                    >
                      Profile
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#emp_projects"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Documents
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#bank_statutory"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Attendance Report
                      {/* <small className="text-danger ms-1">(Admin Only)</small> */}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#emp_assets"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Payslip
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#emp_assets"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Notes
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Profile Info Tab */}
          <ProfileTab details={userData} user={user} />
        </div>
      </div>
    </>
  );
};

export default Profile;
