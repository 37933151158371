import apiSlice from "./api/api";
import { BRANCHES, DEPARTMENTS, DESIGNATIONS, LEAVE_TYPES, TERMINATION_TYPES } from "./ENDPOINTS";

const settingsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // get branches
        getBranches: builder.query({
            query: () => ({
                url: BRANCHES,
                method: "GET",
            }),
            providesTags: ["Branches"],
        }),
        // save branches
        saveBranches: builder.mutation({
            query: (data) => ({
                url: BRANCHES,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["Branches"],
        }),
        // get leave types
        getLeaveTypes: builder.query({
            query: () => ({
                url: LEAVE_TYPES,
                method: "GET",
            }),
            providesTags: ["LeaveTypes"],
        }),
        // fetch leave types
        saveLeaveTypes: builder.mutation({
            query: (data) => ({
                url: LEAVE_TYPES,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["LeaveTypes"],
        }),
        // get departments
        getDepartments: builder.query({
            query: () => ({
                url: DEPARTMENTS,
                method: "GET",
            }),
            providesTags: ["Departments"],
        }),
        // save departments
        saveDepartments: builder.mutation({
            query: (data) => ({
                url: DEPARTMENTS,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["Departments"],
        }),
        // get designations
        getDesignations: builder.query({
            query: () => ({
                url: DESIGNATIONS,
                method: "GET",
            }),
            providesTags: ["Designations"],
        }),
        // save designations
        saveDesignations: builder.mutation({
            query: (data) => ({
                url: DESIGNATIONS,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["Designations"],
        }),
        // get termination types
        getTerminationTypes: builder.query({
            query: () => ({
                url: TERMINATION_TYPES,
                method: "GET",
            }),
            providesTags: ["TerminationTypes"],
        }),
        // save termination types
        saveTerminationType: builder.mutation({
            query: (data) => ({
                url: TERMINATION_TYPES,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["TerminationTypes"],
        }),
        // delete item
        deleteItem: builder.mutation({
            query: (url) => ({
                url,
                method: "DELETE",
            }),
            invalidatesTags: ["Branches", "LeaveTypes", "Leaves", "Departments", "Designations", "TerminationTypes"]
        }),
    })
})

export const {
    useGetBranchesQuery,
    useSaveBranchesMutation,
    useGetDepartmentsQuery,
    useSaveDepartmentsMutation,
    useGetDesignationsQuery,
    useSaveDesignationsMutation,
    useGetLeaveTypesQuery,
    useSaveLeaveTypesMutation,
    useGetTerminationTypesQuery,
    useSaveTerminationTypeMutation,
    useDeleteItemMutation
} = settingsApiSlice;