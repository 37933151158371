import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { PersonalInfoForm } from '../../Forms/PersonalInfoForm'
import { EducationalInfoForm } from '../../Forms/EducationalForm'
import { NextOfKinForm } from '../../Forms/NextOfKinForm'
import { WorkHistoryForm } from '../../Forms/WorkHistoryForm'

const AddEmployee = ({branches, designations, departments}) => {
    const [activePage, setActivePage]=useState(0);
    const [empId, setEmpId] = useState(null);

    return (
        <div>
            {/* Add Contact */}
            <div
                className="modal custom-modal fade custom-modal-two modal-padding"
                id="employee_form"
                role="dialog"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header header-border justify-content-between p-0">
                            <h5 className="modal-title">Add New Employee</h5>
                            <button
                                type="button"
                                className="btn-close position-static"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="add-details-wizard">
                                <ul id="progressbar" className="progress-bar-wizard">
                                    <li  className={activePage === 0 ? "active" : ""} onClick={() => setActivePage(0)}>
                                        <span>
                                            <i className="la la-user-edit" />
                                        </span>
                                        <div className="multi-step-info">
                                            <h6>Personal Info</h6>
                                        </div>
                                    </li>
                                    
                                    <li className={activePage === 1 ? "active" : ""} onClick={() => setActivePage(1)}> 
                                        <div className="multi-step-icon">
                                            <span>
                                                <i className="la la-users" />
                                            </span>
                                        </div>
                                        <div className="multi-step-info">
                                            <h6>Nex of Kin</h6>
                                        </div>
                                    </li>
                                    <li  className={activePage === 2 ? "active" : ""} onClick={() => setActivePage(2)}>
                                        <div className="multi-step-icon">
                                            <span>
                                                <i className="la la-award" />
                                            </span>
                                        </div>
                                        <div className="multi-step-info">
                                            <h6>Education Information</h6>
                                        </div>
                                    </li>
                                    <li  className={activePage === 3 ? "active" : ""} onClick={() => setActivePage(3)}>
                                        <div className="multi-step-icon">
                                            <span>
                                                <i className="la la-user-tie" />
                                            </span>
                                        </div>
                                        <div className="multi-step-info">
                                            <h6>Work Experience</h6>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="add-info-fieldset">
                                {activePage === 0 && 
                                    <fieldset id="first-field">
                                        <PersonalInfoForm 
                                            branches={branches} 
                                            departments={departments} 
                                            designations={designations} 
                                            onNext={(id) => {
                                                console.log(id);
                                                setEmpId(id);
                                                setActivePage(activePage + 1);
                                            }}
                                        />
                                    </fieldset>
                                }

                                {activePage === 1 && 
                                    <fieldset id="first-field">

                                        <NextOfKinForm
                                            empId={empId}
                                            onNext={() => setActivePage(activePage + 1)}
                                            onPrev={() => setActivePage(activePage - 1)}
                                        />
                                    </fieldset>
                                }

                                <fieldset style={{ display: activePage === 2 ? "block" : "none" }}>

                                    <EducationalInfoForm 
                                        empId={empId}
                                        onNext={() => setActivePage(activePage + 1)}
                                        onPrev={() => setActivePage(activePage - 1)}
                                    />
                                </fieldset>
                                <fieldset  style={{ display: activePage === 3 ? "block" : "none" }}>
                                    <WorkHistoryForm 
                                        empId={empId}
                                        onNext={() => setActivePage(activePage + 1)} 
                                        onPrev={() => setActivePage(activePage - 1)}
                                    />
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Contact */}
             {/* Success Contact */}
             <div className="modal custom-modal fade" id="success_msg" role="dialog">
             <div className="modal-dialog modal-dialog-centered">
                 <div className="modal-content">
                     <div className="modal-body">
                         <div className="success-message text-center">
                             <div className="success-popup-icon">
                                 <i className="la la-user-shield" />
                             </div>
                             <h3>Employee Created Successfully!!!</h3>
                             <p>View the details of contact</p>
                             <div className="col-lg-12 text-center form-wizard-button">
                                 <Link to="#" className="button btn-lights" data-bs-dismiss="modal">
                                     Close
                                 </Link>
                                 <Link to="#" className="btn btn-primary">
                                     View Details
                                 </Link>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
         {/* /Success Contact */}
        </div>

    )
}

export default AddEmployee
