import React, { useState } from "react";
import SearchBox from "./SearchBox";
import { Link } from "react-router-dom";

export const FilterBox = ({getResults}) => {
    const [search, setSearch] = useState('');

    return (
        <div className="filter-section activity-filter-head">
            <div className="all-activity-head">
                <SearchBox />
            </div>
            <ul>
            
            <li>
                <div className="search-set">
                <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                        <i className="las la-search" />
                    </Link>
                    <div className="dataTables_filter">
                    <label>
                        {" "}
                        <input
                            type="search"
                            className="form-control form-control-sm"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') getResults(search);
                            }}
                        />
                    </label>
                    </div>
                </div>
                </div>
            </li>
            <li>
                <a
                    href="#"
                    className="btn btn-success w-100"
                    onClick={() => getResults(search)}
                >
                    {' '}
                    Search{' '}
                </a>
            </li>
            </ul>
        </div>
    )
}