import * as Yup from "yup";

export const EmployeeSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, 'Minimum 3 letters')
      .required('First name is required'),
    last_name: Yup.string()
      .min(2, 'Minimum 3 letters')
      .required('Last name is required'),
    email: Yup.string()
        .email("Please enter a valid email address")
        .required("Email is required"),
    address: Yup.string()
      .min(2, 'Minimum 3 letters')
      .required('Enter staff address'),
    phone_number: Yup.string()
      .min(11, 'Minimum 3 letters')
      .required('Please enter a valid phone number'),
    gender: Yup.string().required('This field is required'),
    marital_status: Yup.string().required('This field is required'),
    date_of_birth: Yup.string().required('Date of birth is required'),
    branch_id: Yup.string().required('Please select employee branch'),
    department_id: Yup.string().required('Please select a department'),
    designation_id: Yup.string().required('Please select a designation'),
});

export const ResignationSchema = Yup.object().shape({
    employee_id: Yup.string()
        .min(3, "Minimum 3 letters")
        .required("Please enter a country name"),
    notice_date: Yup.string().required(),
    resignation_date: Yup.string().required(),
});


export const TerminationSchema = Yup.object().shape({
    employee_id: Yup.string()
        .required("Please select an employee"),
    termination_type_id: Yup.string().required('Termination type is required'),
    termination_date: Yup.string().required('Select termination date'),
    reason: Yup.string().min(3, "Minimum 3 letters").required('Please provide a reason')
});

export const TransferSchema = Yup.object().shape({
    employee_id: Yup.string()
        .required("Please select an employee"),
    branch_id: Yup.string().required('Select new branch'),
    transfer_date: Yup.string().required(),
});

export const PromotionSchema = Yup.object().shape({
    employee_id: Yup.string()
        .required("Please select an employee"),
    designation_id: Yup.string().required('Select new designation'),
    promotion_date: Yup.string().required('Enter promtion start date')
});

export const LeaveSchema = Yup.object().shape({
    employee_id: Yup.string()
      .required("Please select an employee"),
    leave_type_id: Yup.string().required('Select leave type'),
    start_date: Yup.string().required('Enter start date'),
    end_date: Yup.string().required('Enter end date'),
    reason: Yup.string().required('Please provide a reason'),
});

export const LoanSchema = Yup.object().shape({
    employee_id: Yup.string()
      .required("Please select an employee"),
    amount: Yup.string().required('Enter a loan amount'),
    tenure: Yup.string().required('Select loan tenure'),
    // start_date: Yup.string().required('Enter start date'),
    // end_date: Yup.string().required('Enter end date'),
    reason: Yup.string().required('Please provide a reason'),
});