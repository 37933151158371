import apiSlice from "./api/api";
import { LEAVES, PROMOTIONS, RESIGNATIONS, TERMINATIONS, TRANSFERS } from "./ENDPOINTS";

const hrApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // get terminations
    getTerminations: builder.query({
        query: ({page, search}) => ({
        url: `${TERMINATIONS}?page=${page}&q=${search}`,
        method: "GET",
        }),
        providesTags: ["Terminations"],
    }),
    // fetch employees
    saveTermination: builder.mutation({
      query: (data) => ({
        url: TERMINATIONS,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Terminations"],
    }),
    // get resignation
    getResignation: builder.query({
      query: ({page, search}) => ({
      url: `${RESIGNATIONS}?page=${page}&q=${search}`,
      method: "GET",
      }),
      providesTags: ["Resignations"],
    }),
    // save resignation
    saveResignation: builder.mutation({
      query: (data) => ({
        url: RESIGNATIONS,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Resignations"],
    }),
    // get promotions
    getPromotions: builder.query({
      query: ({page, search}) => ({
      url: `${PROMOTIONS}?page=${page}&q=${search}`,
      method: "GET",
      }),
      providesTags: ["Promotions"],
    }),
    // save promotion
    savePromotion: builder.mutation({
      query: (data) => ({
        url: PROMOTIONS,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Promotions"],
    }),
    // get transfers
    getTransfers: builder.query({
      query: ({page, search}) => ({
      url: `${TRANSFERS}?page=${page}&q=${search}`,
      method: "GET",
      }),
      providesTags: ["Transfers"],
    }),
    // save transfers
    saveTransfer: builder.mutation({
      query: (data) => ({
        url: TRANSFERS,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Transfers"],
    }),

     // get leaves
     getLeaves: builder.query({
      query: ({filter, page}) => ({
        url: `${LEAVES}/list?page=${page}`,
        method: "POST",
        body: filter,
      }),
      providesTags: ["Leaves"],
    }),
    // save leaves
    saveLeaves: builder.mutation({
      query: (data) => ({
        url: LEAVES,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Leaves"],
    }),
    
  })
});

export const {
    useLazyGetLeavesQuery,
    useSaveLeavesMutation,
    useLazyGetTransfersQuery,
    useSaveTransferMutation,
    useLazyGetPromotionsQuery,
    useSavePromotionMutation,
    useLazyGetTerminationsQuery,
    useSaveTerminationMutation,
    useSaveResignationMutation,
    useLazyGetResignationQuery
  } = hrApiSlice;