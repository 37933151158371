import React, {useEffect, useState, Fragment} from "react";
import NextOfKinModal from "../../../../components/modelpopup/Profile/NextOfKinModal";
import { Empty } from "antd";
import { Link } from "react-router-dom";

export const EmergencyContacts = ({details}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(details?.nextOfKins);
    }, [details]);

    const refreshData = data => {
        // setData(data);
    }

    return (
        <div className="card profile-box flex-fill">

                <div className="card-body">
                    <h3 className="card-title">Emergency Contact (Next Of Kin)
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#emergency_contact_modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                    </h3>
                    {data && data.length > 0 ?
                        data.map((row, i) =>
                            <Fragment key={i}>
                                <h5 className="section-title">{i === 0 ? 'Primary' : 'Secondary'}</h5>
                                <ul className="personal-info">
                                    <li>
                                        <div className="title">Name</div>
                                        <div className="text">{row.name}</div>
                                    </li>
                                    <li>
                                        <div className="title">Relationship</div>
                                        <div className="text">{row.relationship}</div>
                                    </li>
                                    <li>
                                        <div className="title">Phone </div>
                                        <div className="text">{row.phone_number}</div>
                                    </li>
                                    <li>
                                        <div className="title">Address </div>
                                        <div className="text">{row.address}</div>
                                    </li>
                                </ul>
                                {i === 0 && <hr /> }
                            </Fragment>
                        )
                    :
                        <div className="text-center">
                            <Empty />
                            <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#emergency_contact_modal">
                                <i className="la la-plus mr-1" />Add
                            </button>
                        </div>
                    }
                </div>

            {/* Emergency Contact Modal */}
            <NextOfKinModal data={details} refresh={refreshData} />
            {/* /Emergency Contact Modal */}
        </div>
    )
}
