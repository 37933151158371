import React, { useEffect } from "react"
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {ClipLoader} from "react-spinners";
import {DatePicker} from "antd";
import alertify from "alertifyjs";
import { useSaveEducationMutation } from '../../services/employee.service'
import dayjs from "dayjs";


export const EducationalInfoForm = ({ empId, onPrev, onNext, profile, isEditing=false}) => {
    const [saveInfo, {isLoading, data, error}] = useSaveEducationMutation();

    const {
        control,
        reset,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm({
        // resolver: yupResolver(EmployeeSchema),
        defaultValues: {details: [
            {
                institution: '',
                certificate: '',
                start_date: '',
                end_date: '',
                id: ''
            },
        ]}
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "details"
      })

    useEffect(() => {
        if (data && data.success) {
          onNext(+1)
        }
    
        if (error) {
          alertify.error(error?.message);
        }
    }, [data, error]);

    const save = payload => {
        // console.log(payload)
        saveInfo({data: payload, id: empId});
    }
    
    useEffect(() => {

        if(profile?.educations.length) {
            const form = {details: []};

            profile.educations.forEach(row => {
                form.details.push({
                    institution: row.institution,
                    certificate: row.certificate,
                    start_date: dayjs(row.start_date) || null,
                    end_date: dayjs(row.end_date) || null,
                    id: row.id
                })
            });

            reset(form);
        }
    }, [profile]);

    return (
            <form onSubmit={handleSubmit(save)}>
                
                <div className="contact-input-set">
                    <div className="row">
                        {fields.map((item, i) =>
                            <div className="card" key={`education-form-${i}`}>
                                <div className="card-body">
                                    <h3 className="card-title">
                                        Education Information
                                        {i !== 0 &&
                                            <a href="javascript:;" onClick={() => remove(i)}
                                                className="delete-icon"><i className="fa fa-trash" /></a>
                                        }
                                    </h3>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group form-focus focused">
                                                <label className="focus-label">Institution</label>
                                                <Controller
                                                    name={`details.${i}.institution`}
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control`}
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group form-focus focused">
                                                <label className="focus-label">Certificate Obtained</label>
                                                <Controller
                                                    name={`details.${i}.certificate`}
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control`}
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group form-focus focused">
                                                <label className="focus-label">Starting Date</label>
                                                <div className="cal-icon">
                                                    <Controller
                                                        name={`details.${i}.start_date`}
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <DatePicker
                                                                bodyClassName="w-100"
                                                                id="start_date"
                                                                picker="month"
                                                                className="form-control"
                                                                title={`Start Date`}
                                                                value={value}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group form-focus focused">
                                                <label className="focus-label">Complete Date</label>
                                                <div className="cal-icon">
                                                    <Controller
                                                        name={`details.${i}.end_date`}
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <DatePicker
                                                                bodyClassName="w-100"
                                                                id="end_date"
                                                                picker="month"
                                                                className="form-control"
                                                                title={`Start Date`}
                                                                value={value}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {i + 1 === getValues('details').length &&
                                    <div className="add-more mt-2">
                                        <a href="javascript:;"
                                        onClick={() => append({
                                            institution: '',
                                            certificate: '',
                                            start_date: '',
                                            end_date: '',
                                            id: ''
                                        })}><i className="fa fa-plus-circle" /> Add More</a>
                                    </div>}
                                </div>
                            </div>
                        )}
                        <div className="col-lg-12 d-flex justify-content-between form-wizard-button">
                            <button
                                className="button btn-lights reset-btn"
                                type="reset"
                                onClick={reset}
                            >
                                Reset
                            </button>
                            <div className='text-end'>
                                {!isEditing && 
                                    <button
                                        className="button btn-lights reset-btn"
                                        type="button"
                                        onClick={() => onPrev()}
                                    >
                                        Prev
                                    </button>
                                }
                                <button
                                    className="btn btn-primary wizard-next-btn"
                                    type="submit" 
                                >
                                    {!isLoading ? 'Save & Next' : <ClipLoader color='#ffffff' />}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
    )
}