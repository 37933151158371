import React, { useEffect } from "react"
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {ClipLoader} from "react-spinners";
import alertify from "alertifyjs";
import { useSaveNextOfKinMutation } from '../../services/employee.service'


export const NextOfKinForm = ({ empId, onPrev, onNext, profile, isEditing = false}) => {
    const [saveInfo, {isLoading, data, error}] = useSaveNextOfKinMutation();

    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        // resolver: yupResolver(EmployeeSchema),
        defaultValues: {details: [
            {
                name: '',
                relationship: '',
                address: '',
                phone_number: '',
                id: ''
            },
            {
                name: '',
                relationship: '',
                address: '',
                phone_number: '',
                id: ''
            }
        ]}
    });

    const { fields } = useFieldArray({
        control,
        name: "details"
      })

    useEffect(() => {
        if (data && data.success) {
          onNext(+1)
        }
    
        if (error) {
          alertify.error(error?.message);
        }
    }, [data, error]);

    const save = payload => {
        // console.log(payload)
        saveInfo({data: payload, id: empId});
    }

    useEffect(() => {
        if(profile?.nextOfKins.length) {
            const form = {details: [
                {
                    name: profile?.nextOfKins[0]?.name || '',
                    relationship: profile?.nextOfKins[0]?.relationship ||  '',
                    address: profile?.nextOfKins[0]?.address || '',
                    phone_number: profile?.nextOfKins[0]?.phone_number || '',
                    id: profile?.nextOfKins[0]?.id || ''
                },
                {
                    name: profile?.nextOfKins[1]?.name || '',
                    relationship: profile?.nextOfKins[1]?.relationship || '',
                    address: profile?.nextOfKins[1]?.address || '',
                    phone_number: profile?.nextOfKins[1]?.phone_number || '',
                    id: profile?.nextOfKins[1]?.id || ''
                }
            ]}
            reset(form);
        }
    }, [profile]);

      
    return (
            <form onSubmit={handleSubmit(save)}>
                
                <div className="contact-input-set">
                    <div className="row">
                        {fields.map((data, i) =>
                            <div className="card" key={i}>
                                <div className="card-body">
                                    <h3 className="card-title">{ i === 0 ? 'Primary' : 'Secondary'} Contact</h3>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group">
                                                <label>Name <span className="text-danger">*</span></label>
                                                <Controller
                                                    name={`details.${i}.name`}
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control`}
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group">
                                                <label>Relationship <span className="text-danger">*</span></label>
                                                <Controller
                                                    name={`details.${i}.relationship`}
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control`}
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Address <span className="text-danger">*</span></label>
                                                <Controller
                                                    name={`details.${i}.address`}
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control`}
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Phone <span className="text-danger">*</span></label>
                                                <Controller
                                                    name={`details.${i}.phone_number`}
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control`}
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="col-lg-12 d-flex justify-content-between form-wizard-button">
                            <button
                                className="button btn-lights reset-btn"
                                type="reset"
                                onClick={() => reset()}
                            >
                                Reset
                            </button>
                            <div className='text-end'>
                            {!isEditing &&
                                <button
                                    className="button btn-lights reset-btn"
                                    type="button"
                                    onClick={() => onPrev()}
                                >
                                    Prev
                                </button>
                            }
                                <button
                                    className="btn btn-primary wizard-next-btn"
                                    type="submit" 
                                >
                                    {!isLoading ? 'Save & Next' : <ClipLoader color='#ffffff' />}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
    )
}